<Page infinite infiniteDistance={50} onInfinite={() => visibleRowCount += PAGE_ROW_COUNT} infinitePreloader={false}>
    <Navbar>
        <NavLeft>
            <img class="logo" src={logo} alt="logo"/>
            <span>{title} tabel</span>
        </NavLeft>
      <NavRight>
        <Link on:click={exportCsv}>Export csv</Link>
        <Link popupClose>Sluiten</Link>
      </NavRight>
    </Navbar>
    <Block>
        <div>
            <label>
                <input type="radio" bind:group={routesChoice} value={ROUTES_CHOICE_ALL}/>
                Alle routes
            </label>
            <label>
                <input type="radio" bind:group={routesChoice} value={ROUTES_CHOICE_ACTIVE}/>
                Actieve routes
            </label>
            <label>
                <input type="radio" bind:group={routesChoice} value={ROUTES_CHOICE_CUSTOM}/>
                Aangepast
            </label>
            {#if routesChoice == ROUTES_CHOICE_CUSTOM}
            <List>
                <ListItem title="Filter {title.toLowerCase()}s" smartSelect smartSelectParams={{openIn: 'popup', searchbar: true, searchbarPlaceholder: "Zoek " + title.toLowerCase()}}>
                    <select multiple bind:value={headerFilters}>
                        {#each headerOptions as option}
                            <option value={option}>{option}</option>
                        {/each}
                    </select>
                </ListItem>
            </List>
            {/if}
        </div>
        <div style="width: 95%">
            
        <!-- Obscure #key is necessary to make "max" property responsive. See https://svelte.dev/repl/cdec4ea57d9c420e8d9fd87f6f59d43d?version=3.38.2 -->
        {#key [ntimestamps]}
        <Range min={0} max={ntimestamps - 1} 
            step={1} dual={true} label={true} 
            value={editableTimestampRange} 
            onRangeChange={onTimeRangeChange}/>
        {/key}
        <p>
            {editableTimestampRange[0] === editableTimestampRange[1] ? 
                new Date(allTimestamps?.[editableTimestampRange[0]] ?? 0).toLocaleString() :
                `${new Date(allTimestamps?.[editableTimestampRange[0]] ?? 0).toLocaleString()} - ${new Date(allTimestamps?.[editableTimestampRange[1]] ?? 0).toLocaleString()}`}
        </p>
        </div>
        <div>
          <Toggle bind:checked={followLiveData}></Toggle> Live data volgen 
        </div>
        <div>
            <Button disabled={followLiveData} on:click={() => appliedTimestampRange = [...editableTimestampRange]}>Toepassen</Button>
        </div>
    </Block>
        <div class="data-table">
            <table>
                <thead>
                    <tr>
                        {#each headers as header}
                            <th class="label-cell sortable-cell {getSortClass(header, sortColumn, sortDirection)}" 
                                on:click={() => toggleSort(header)}>{header}</th>
                        {/each}
                        <th class="label-cell sortable-cell {getSortClass('Timestamp', sortColumn, sortDirection)}" 
                            on:click={() => toggleSort('Timestamp')}>Timestamp</th>
                        {#each indicators as ind}
                        <th class="numeric-cell sortable-cell {getSortClass(ind.displayName, sortColumn, sortDirection)}" 
                            on:click={() => toggleSort(ind.displayName)}>{ind.displayName} ({ind.unit})</th>
                        {/each}
                    </tr>
                </thead>
                <tbody>
                {#each visibleIndicatorData as data}
                    <tr>
                        {#each data.Header as header}
                            <td class="label-cell">{header}</td>
                        {/each}
                        <td class="label-cell">{new Date(data.Timestamp).toLocaleString()}</td>
                        {#each data.Values ?? [] as ind, index}
                            <td class="numeric-cell">{formatNumber(ind.value, indicators[index])}</td>
                        {/each}
                    </tr>
                {/each}
                </tbody>
            </table>
        </div>
</Page>

<script lang="ts">
    import { Block, Link, NavRight, Navbar, Page, Range, Button, Toggle, List, ListItem, NavLeft } from "framework7-svelte";
    import { indicators, type IIndicatorData, type IIndicator } from "../utils/indicators";
    import type { IInsight, IMeasurement } from "../utils/apitypes";
    import { formatDuration } from "../utils/style";
    import logo from '../assets/lisa-buko.svg';

    const ROUTES_CHOICE_ALL = 'allRoutes';
    const ROUTES_CHOICE_ACTIVE = 'activeRoutes';
    const ROUTES_CHOICE_CUSTOM = 'customRoutes';
    const PAGE_ROW_COUNT = 100;
    
    export let title;
    export let headers: string[];
    export let allTimestamps: string[];
    export let measurementSummaryProvider: (timestamps: string[]) => void;
    export let insight: IInsight;
    export let csvName: string = 'export.csv';
    export let indicatorDataFunc: (insight: IInsight, timestamp: string, measurement: IMeasurement, activeRoutesOnly: boolean) => IIndicatorData[];
    export let allKnownMeasurements: IMeasurement[];
    let followLiveData: boolean = true;
    let visibleRowCount = 1000;
    let editableTimestampRange: [number, number] = [0, 0];
    let appliedTimestampRange: [number, number] = [0,-1];
    let headerFilters: string[] = [];
    let routesChoice: string = ROUTES_CHOICE_ALL;
    let sortColumn: string;
    let sortDirection: number = 1;
    $: ntimestamps = allTimestamps?.length ?? 0;
    $: indicatorData = deriveIndicatorData(allKnownMeasurements, appliedTimestampRange, routesChoice);
    $: if (editableTimestampRange[1] === 0) { editableTimestampRange = [editableTimestampRange[0], allTimestamps?.length - 1]; }
    $: if (followLiveData) { editableTimestampRange = [ntimestamps - 1, ntimestamps - 1]; appliedTimestampRange = [...editableTimestampRange]; }
    $: visibleIndicatorData = sort(filter(indicatorData, headerFilters, routesChoice, visibleRowCount), sortColumn, sortDirection);
    $: headerOptions = getHeaderOptions(indicatorData);
    $: refresh(appliedTimestampRange);

    function getHeaderOptions(data: IIndicatorData[]): string[] {
        const options: string[] = [];
        for (const datum of data) {
            for (const header of datum.Header) {
                if (options.indexOf(header) < 0) {
                    options.push(header);
                }
            }
        }
        options.sort();
        return options;
    }

    function getSortClass(header: string, sortColumn: string, sortDirection: number): string {
        if(sortColumn !== header) return '';
        return 'sortable-cell-active ' + (sortDirection === 1 ? 'sortable-asc' : 'sortable-desc');
    }

    function toggleSort(header: string) {
        if(sortColumn === header){
            sortDirection = -sortDirection;
        } else {
            sortColumn = header;
            sortDirection = 1;
        }
    }

    function filter(data: IIndicatorData[], headerFilters: string[], routesChoice: string, visibleRowCount: number): IIndicatorData[] {
        if (routesChoice == ROUTES_CHOICE_ALL) {
            return data.slice(0, visibleRowCount);
        }
        const filtered: IIndicatorData[] = [];
        let i: number = -1;
        while(filtered.length < visibleRowCount && ++i < data.length) {
            const datum = data[i];
            if (!datum) continue;
            let allow = headerFilters.length === 0;
            if (!allow) {
                for(const header of datum.Header) {
                    if (headerFilters.indexOf(header) >= 0) {
                        allow = true;
                        break;
                    }
                }
            }
            if(!allow) continue;
            filtered.push(datum);
        }
        return filtered;
    }

    function sort(data: IIndicatorData[], sortColumn: string, sortDirection: number): IIndicatorData[] {
        if (headers.includes(sortColumn)) {
            const columnIndex = headers.indexOf(sortColumn);
            data.sort((a, b) => sortDirection * a.Header[columnIndex].localeCompare(b.Header[columnIndex]));
        } else if (sortColumn === 'Timestamp') {
            data.sort((a, b) => sortDirection * a.Timestamp.localeCompare(b.Timestamp));
        } else {
            const columnIndex = indicators.findIndex(ind => ind.displayName === sortColumn);
            data.sort((a, b) => sortDirection * ((a.Values[columnIndex]?.value ?? 0) - (b.Values[columnIndex]?.value ?? 0)));
        }
        return data;
    }
    
    function formatNumber(value: number | undefined, ind: IIndicator) {
        if ((value === undefined) || (isNaN(value))) {
            return '-';
        }
        if (ind.type === 'duration') {
            return formatDuration(value);
        }
        return value.toFixed(2);
    }

    function formatCsvNumber(value: number | undefined) {
        if ((value === undefined) || (isNaN(value))) {
            return '-';
        }
        return value.toFixed(2);
    }

    function deriveIndicatorData(measurements: IMeasurement[], range: [number, number], routesChoice: string) {
        const newIndicatorData: IIndicatorData[] = [];
        
        // Note: The measurements may have different indices than the timestamps, because we typically only
        // receive measurements for the selected part of all available timestamps. Therefore, we iterate over
        // all measurements (instead of just taking the ones with the expected indices).
        const low = allTimestamps?.[appliedTimestampRange[0]];
        const high = allTimestamps?.[appliedTimestampRange[1]];
        if ((low !== undefined) && (high !== undefined)) {
            for (const measurement of measurements ?? []) {
                if ((measurement.Timestamp >= low) && (measurement.Timestamp <= high)) {
                    newIndicatorData.push(...indicatorDataFunc(insight, measurement.Timestamp, measurement, routesChoice === ROUTES_CHOICE_ACTIVE));
                }
            }
        }

        newIndicatorData.sort((a, b) => headers.map((h, i) => a.Header[i].localeCompare(b.Header[i])).reduce((acc, curr) => acc || curr, 0) || a.Timestamp.localeCompare(b.Timestamp));
        return newIndicatorData;
    }
    
    function generateCsv() {
        let csv: string[] = [];
        csv.push(indicators.reduce((acc, cur) => acc + `,${cur.displayName} (${cur.unit})`, headers.join(',') + ',Timestamp'));
        for (const data of indicatorData){
            csv.push(data.Values.reduce((acc, cur) => acc + `,${formatCsvNumber(cur?.value)}`, data.Header.join(',') + ',' + data.Timestamp));
        }
        return csv.join('\n');
    }

    function exportCsv(){
        exportToFile(generateCsv(), 'text/plain', csvName);
    }

    function exportToFile(data: string, contentType: string, fileName: string) {
        const blob = new Blob([data], {type: contentType});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
    }

    async function onTimeRangeChange(values) {
        // Due to a bug (at least, looks like a bug) when selecting entire time line, the first and last
        // index can be reversed. Always keep this in consistent order.
        if (editableTimestampRange[0] > editableTimestampRange[1]) {
            editableTimestampRange.reverse()
        }
        // Prevent situation in which checking followLiveDate changes the timestamp range which then triggers
        // this handler which then disables followLiveData.
        if ( (values[0] !== editableTimestampRange[0]) || (values[1] !== editableTimestampRange[1]) ) {
            followLiveData = false;
        }
        editableTimestampRange = values;
    }

    function refresh(range: [number, number]) {
        const applicableTimestamps = [];
        for (let idx = range[0]; idx <= range[1]; idx++) {
            if (allTimestamps?.[idx]) {
                applicableTimestamps.push(allTimestamps[idx]);
            }
        }
        measurementSummaryProvider(applicableTimestamps);
        visibleRowCount = PAGE_ROW_COUNT;
    }
</script>