{#if $darkmode}
<Link class="link--level-deeper" iconOnly on:click={() => setDarkMode(false)} iconMaterial="light_mode" ></Link>
{:else}
<Link class="link--level-deeper" iconOnly on:click={() => setDarkMode(true)} iconMaterial="dark_mode" ></Link>
{/if}
<Link class="link--level-deeper" iconOnly popoverOpen=".settings-menu" iconMaterial="settings" ></Link>

<Popover class="settings-menu" style="width: 200px">
    <List ul={false}>
        <ListGroup>
            <ListItem link="#" title="Mijn account">
                <Icon slot="media" material="person_outline"/>
            </ListItem>
            <ListItem link="#" title="?"/>
            <ListItem link="#" title="?"/>
            <ListItem link="#" title="?"/>
            <ListItem link="#" title="Uitloggen" on:click={signOut} popoverClose/>
        </ListGroup>
    </List>
</Popover>

<script>
    import { Icon, Link, List, ListGroup, ListItem, Popover, f7 } from "framework7-svelte";
    import { darkmode, setDarkMode } from "../utils/darkmode";
    import { doSignOut } from "../utils/credentials";
    
    function signOut() {
        doSignOut();
        f7.views.main?.router?.navigate('/');
    }
</script>