import type { IImportedMeasurement, IImportedMeasurements } from "./apitypes";

export function parseMeasurementsCsv(csv: string, projectId: string): IImportedMeasurements {
    const data: IImportedMeasurements = {
        ProjectId: projectId,
        Measurements: []
    };

    const lines = csv.split('\n'); //.slice(1);  // Ignore 1st header line
    for (const line of lines) {
        if (line.trim() === '') {
            continue;
        }
        
        const measurement = parseMeasurement(line);
        data.Measurements.push(measurement);
    }

    return data;
}

function parseMeasurement(line: string): IImportedMeasurement {
    const fields = line.split(',');
    return { 
        SegmentId: fields[0],
        Timestamp: fields[1],
        Distance: parseInt(fields[2]),
        Duration: parseFloat(fields[3])
    };
}
