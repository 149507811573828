﻿<FloatingButton 
        label="Puntmetingen" 
        active={modeNdwActive || modeNdwInactive}
        activeIcon="check"
        inactiveIcon=""
        popoverWidth="336px">
    
    <List ul={false}>
        <ListGroup>
            <ListItem title="Weergave" groupTitle />
            
            <ListItem 
                    checkbox 
                    checkboxIcon="end" 
                    title="Actieve intensiteitsinformatie" 
                    bind:checked={modeNdwActive} />
            
            <ListItem 
                    checkbox 
                    checkboxIcon="end" 
                    title="Inactieve intensiteitsinformatie" 
                    bind:checked={modeNdwInactive} />
            
        </ListGroup>

        <hr class="divider--direction-horizontal"/>
        
        <ListGroup>
            <ListItem title="Waardes tonen" groupTitle />
            
            <ListItem 
                    checkbox 
                    checkboxIcon="end" 
                    title="Snelheid" 
                    bind:checked={showNdwSpeed} />
            
            <ListItem 
                    checkbox 
                    checkboxIcon="end" 
                    title="Intensiteit" 
                    bind:checked={showNdwFlow} />
            
        </ListGroup>
    </List>
    
</FloatingButton>

<script>
    import {List, ListGroup, ListItem} from "framework7-svelte";
    import FloatingButton from "../FloatingButton.svelte";

    export let modeNdwActive = false;
    export let modeNdwInactive = false;
    export let showNdwSpeed = false;
    export let showNdwFlow = false;
    
</script>