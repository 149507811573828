﻿<FloatingButton 
        label="Kleurcodering routes" 
        active={!!selectedIndicator}
        activeIcon="check"
        inactiveIcon=""
        popoverWidth="318px">
    
    <List ul="{false}">
        <ListGroup>
            
            <ListItem groupTitle title="Here routing" />
            
            <ListItem 
                    radio 
                    radioIcon="end"
                    title="Niet weergeven"
                    checked={!selectedIndicator} 
                    on:change={() => selectIndicator(null)} />
            
            {#each indicators as ind}
                <ListItem 
                        radio 
                        radioIcon="end"
                        title={`${ind.displayName} (${ind.unit})`}
                        checked={selectedIndicator === ind.id} 
                        on:change={() => selectIndicator(ind.id)}/>
            {/each}

            <hr class="divider--direction-horizontal"/>

            <ListItem 
                    checkbox 
                    checkboxIcon="end" 
                    title="Waardes tonen" 
                    onChange="{changeShowValues}"/>
            
        </ListGroup>
    </List>
</FloatingButton>

<script lang="ts">
    import {indicators} from "../../utils/indicators.js";
    import {List, ListGroup, ListItem} from "framework7-svelte";
    import FloatingButton from "../FloatingButton.svelte";
    import {createEventDispatcher} from "svelte";
    
    export let selectedIndicator: string | null = '';

    const eventDispatcher = createEventDispatcher();

    function selectIndicator(indicator: string | null) {
        selectedIndicator = indicator;
        eventDispatcher('indicatorSelected', indicator);
    }
    
    function changeShowValues(event) {
        console.log(event.target.checked);
        eventDispatcher('showValuesChanged', event.target.checked);
    }
    
    
</script>