<div class="action-bar">
  <slot></slot>
</div>

<style>
    .action-bar {
        text-align: right;
        padding-left: 2rem;
        padding-right: 2rem;
    }
</style>