﻿<div class="map {isAddingGate ? 'add-gate' : ''}">
    <div use:mapMounted class="map"/>
</div>

<script lang="ts">
    import * as Leaflet from 'leaflet';
    import 'leaflet/dist/leaflet.css';
    import type {IGate, IRoute} from "../../../../lib/webapi/apitypes";
    import type {LatLngExpression} from "leaflet";
    import {getLegendColorRGB, getLegendColorString} from "../../utils/style";
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher<{
        gate: string; 
        latlng: Leaflet.LatLng
    }>();

    const activeColor: string = '#930010';

    let map: Leaflet.Map;

    export let visible: boolean;
    export let center: Leaflet.LatLngExpression;
    export let gates: IGate[];
    export let routes: IRoute[];
    export let selectedRoute: IRoute;
    export let selectedGate: IGate;
    export let highlightedGate: IGate;
    export let zoom: number;
    export let isAddingGate = false;

    let routeLineLayerGroup: Leaflet.LayerGroup;
    let gateCircleLayerGroup: Leaflet.LayerGroup;

    $: if (visible && map && center) {
        initializeMap();
    }

    $: if (visible && map && gates && routes) {
        updateRouteLines(routes, selectedRoute);
        updateGateCircles(gates, selectedGate, highlightedGate);
        if (selectedRoute) {
            if (highlightedGate) {
                flyToHighlightedGate(highlightedGate);
            } else if (selectedGate) {
                flyToGate(selectedGate);
            } else {
                flyToRoute(selectedRoute);
            }
        } else {
            if (gates.length > 0) {
                flyToRouteOverview(gates);
            }
        }
    }

    function updateRouteLines(routesToDisplay: IRoute[], selectedRoute: IRoute) {        
        const routeLines = routesToDisplay
            .map((route, index) => [route, getLegendColorRGB(index)] as [IRoute, [number, number, number]])
            .filter(tuple => tuple[0].Gates.length > 1)
            .map(tuple => {
                const routeGates = tuple[0].Gates
                    .map(gateId => gates.find(gate => gate.Id === gateId))
                    .map(gate => [gate.Coord.Lat, gate.Coord.Lng] as LatLngExpression);

                if (tuple[0].Id === selectedRoute?.Id) {
                    return Leaflet.polyline(routeGates, {color: getLegendColorString(tuple[1], 1), weight: 5});
                }
                return Leaflet.polyline(
                    routeGates, 
                    {
                        color: getLegendColorString(tuple[1], .3), 
                        dashArray: '10, 20', dashOffset: '20'
                    });
            });

        const newLayerGroup = Leaflet.layerGroup(routeLines);
        newLayerGroup.addTo(map);
        if (routeLineLayerGroup) {
            map.removeLayer(routeLineLayerGroup);
        }
        routeLineLayerGroup = newLayerGroup;
    }

    function updateGateCircles(gatesToDisplay: IGate[], selectedGate: IGate, highlighedGate: IGate | undefined) {
        const gateCircles = gatesToDisplay
            .map(gate => {
                if (selectedGate?.Id === gate.Id) {
                    return Leaflet.circleMarker(
                        [gate.Coord.Lat, gate.Coord.Lng] as LatLngExpression,
                        {radius: 7, color: activeColor, fillColor: activeColor, fillOpacity: 1, opacity: 1})
                        .bindTooltip(gate.Name)
                        .addEventListener('click', () => handleGateClick(gate))
                }
                if (highlightedGate?.Id === gate.Id) {
                    return Leaflet.circleMarker(
                        [gate.Coord.Lat, gate.Coord.Lng] as LatLngExpression,
                        {radius: 9, color: activeColor, fillColor: activeColor, fillOpacity: 1, opacity: 1})
                        .bindTooltip(gate.Name)
                        .addEventListener('click', () => handleGateClick(gate))
                }
                return Leaflet.circleMarker(
                    [gate.Coord.Lat, gate.Coord.Lng] as LatLngExpression,
                    {radius: 5, color: 'transparent', fillColor: 'black', fillOpacity: .5})
                    .bindTooltip(gate.Name)
                    .addEventListener('click', () => handleGateClick(gate))
            });

        const newLayerGroup = Leaflet.layerGroup(gateCircles);
        newLayerGroup.addTo(map);
        if (gateCircleLayerGroup) {
            map.removeLayer(gateCircleLayerGroup);
        }
        gateCircleLayerGroup = newLayerGroup;
    }

    function flyToRoute(route: IRoute) {
        const routeGates = route.Gates
            .map(gateId => gates.find(gate => gate.Id === gateId))
            .filter(gate => gate !== undefined)
            .map(gate => [gate.Coord.Lat, gate.Coord.Lng] as LatLngExpression);
        
            if (routeGates.length === 0) {
            return;
        }

        const newBounds = Leaflet.latLngBounds(routeGates).pad(.3);
        if (map.getBounds().contains(newBounds)) {
            return;
        }
        map.flyToBounds(newBounds, {duration: .5});
    }

    function flyToHighlightedGate(gate: IGate) {
        const newBounds: [number, number] = [gate.Coord.Lat, gate.Coord.Lng];
        if (map.getBounds().contains(newBounds)) {
            return;
        }
        map.flyTo([gate.Coord.Lat, gate.Coord.Lng], map.getZoom(), {duration: .5});
    }

    function flyToGate(gate: IGate) {
        const newBounds: [number, number] = [gate.Coord.Lat, gate.Coord.Lng];
        if (map.getBounds().contains(newBounds)) {
            return;
        }        
        map.flyTo(newBounds, map.getZoom(), {duration: .5});
    }
    
    function flyToRouteOverview(allGates: IGate[]) {
        const newBounds = Leaflet.latLngBounds(allGates
            .map(gate => [gate.Coord.Lat, gate.Coord.Lng] as LatLngExpression))
            .pad(.1);
        if (map.getBounds().contains(newBounds)) {
            return;
        }        
        map.flyToBounds(newBounds, {duration: .5});
    }

    function mapMounted(container: HTMLElement) {
        map = createMap(container);
        addControls(map);
    }

    function handleGateClick(gate: IGate) {
        dispatch('gate', gate.Id);
    }

    function createMap(container: HTMLElement) {
        let m = Leaflet.map(container, {
            zoomSnap: 0.25,      // Allow more fluent zooming: not only from one tile level to the next (factor 2), but also in between
            zoomDelta: 0.25,
            preferCanvas: true,  // Drastically speeds up rendering by using canvas instead of separate DOM elements per layer,
            zoomControl: false   // We add our own zoom control in the bottom left corner
        });

        Leaflet.tileLayer(
            'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
            {
                attribution: `&copy;<a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>,
            &copy;<a href="https://carto.com/attributions" target="_blank">CARTO</a>`,
                subdomains: 'abcd',
                maxZoom: 20,
                tileSize: 512,
                zoomOffset: -1,
            })
            .addTo(m);

        m.on('click', (e) => {
            var location= e.latlng;
            dispatch('latlng' , location);
        });

        return m;
    }

    function addControls(map: Leaflet.Map) {
        Leaflet.control
            .zoom({position: 'bottomleft'})
            .addTo(map);
    }

    function initializeMap() {
        map?.invalidateSize();
        map?.setView(center, zoom);
    }
</script>

<style lang="scss">
  @import "../../assets/scss/colors";

  .map {
    width: 100%;
    height: 100%;
    position: relative;
  }

  :global(.add-gate .leaflet-container) {
      cursor: crosshair;
  }

</style>