﻿<FloatingButton
        label="{selectedTimestamp ? formatMoment(selectedTimestamp) : 'Timestamp'}"
        active={false}
        inactiveIcon="Schedule"
        inactiveIconClass="fb-icon-dark-primary"
        popoverHeight="440px"
        popoverWidth="558px"
        hoverControlHeight="56px"
        hoverControlWidth="310px">
    
    <div class="popover-content" slot="hover-control">
        <div class="increment-buttons">
            {#each Object.keys(steps).sort((keyA, keyB) => parseInt(keyA) - parseInt(keyB)) as key}
                <Button
                        on:click={() => incrementTimestamp(parseInt(key))}
                        disabled="{!steps[key]}">
                    {key}
                </Button>
            {/each}
        </div>
    </div>

    <div class="popover-content">
        
        <div class="datepicker-container">
            <DatePicker
                    enabledTimestamps="{sortedTimestamps}"
                    selectedTimestamp="{selectedTimestamp}"
                    on:selected={(ev) => handleSelectedTimestamp(ev.detail)}/>
        </div>
        
        <div class="scroll-container">
            <div class="scroll-parent">
                <Treeview>
                    {#each timestampOptions as section}
                        <TreeviewItem
                                itemToggle
                                on:treeviewOpen={() => section.opened = true}
                                opened="{section.timestamps.includes(selectedTimestamp) || section.opened}"
                                label={section.label}>
                            {#each section.timestamps ?? [] as timestamp}
                                <TreeviewItem
                                        label={formatMomentTime(timestamp)}
                                        selectable
                                        onClick={() => selectedTimestamp = timestamp}
                                        selected={selectedTimestamp === timestamp}>
                                    <span slot="label-start">
                                        {#if selectedTimestamp === timestamp}
                                            <Icon material="check"/>
                                        {:else}
                                            <Icon material="schedule"/>
                                        {/if}                                   
                                    </span>
                                </TreeviewItem>
                            {/each}
                        </TreeviewItem>
                    {/each}
                </Treeview>
            </div>
        </div>
    </div>


</FloatingButton>

<script lang="ts">
    import {formatMoment, formatMomentTime} from "../../utils/style.js";
    import {Button, Icon, Treeview, TreeviewItem} from "framework7-svelte";
    import FloatingButton from "../FloatingButton.svelte";
    import {clamp} from "../../utils/mathutils";
    import { createEventDispatcher } from "svelte";
    import DatePicker from "../DatePicker.svelte";

    interface ITimestampOption {
        label: string,
        timestamps: Date[],
        opened?: boolean
    }

    const eventDispatcher = createEventDispatcher();
    
    export let timestamps: string[] = [];
    $: sortedTimestamps = timestamps.sort().reverse().map(timestamp => new Date(timestamp));
    $: timestampOptions = sortedTimestamps
        .filter(timestamp => new Date(timestamp).setHours(0,0,0,0) === new Date(selectedTimestamp).setHours(0,0,0,0))
        .reduce((sections, timestamp) => {
            const hourLabel = timestamp.toLocaleTimeString().split(":")[0] + ":00";
            const section = sections.find(section => section.label === hourLabel);
            if (section) {
                section.timestamps.push(timestamp);
            } else {
                sections.push({
                    label: hourLabel,
                    timestamps: [timestamp]
                });
            }
            return sections;
        }, [] as ITimestampOption[]);
    
    $: selectedTimestamp = selectedTimestamp ?? sortedTimestamps[0];
        
    $: if(selectedTimestamp){
        eventDispatcher('timestampSelected', selectedTimestamp);
        updateSteps();
    }
    
    let steps: { [key: string]: boolean } = {
        "-20": false,
        "-5": false,
        "-1": false,
        "+1": false,
        "+5": false,
        "+20": false
    }

    function incrementTimestamp(amount: number) {
        const currentIndex = sortedTimestamps.indexOf(selectedTimestamp);
        
        let newIndex = currentIndex - amount;
        newIndex = clamp(newIndex, 0, sortedTimestamps.length - 1);
        
        selectedTimestamp = sortedTimestamps[newIndex];
    }
    
    function updateSteps(){
        const currentIndex = sortedTimestamps.indexOf(selectedTimestamp);
        
        Object.keys(steps)
            .forEach(key => {
                const newIndex = currentIndex - parseInt(key);
                steps[key] = newIndex >= 0 && newIndex < sortedTimestamps.length;
            });
    }

    function handleSelectedTimestamp(date: Date) {
        const d = date.setHours(0,0,0,0);
        selectedTimestamp = sortedTimestamps.find(timestamp => new Date(timestamp).setHours(0,0,0,0) === d) ?? selectedTimestamp;
    }

</script>

<style lang="scss">
  
  :global(.popover-inner) {
    height: 100%
  }

  :global(.fb-icon-dark-primary) {
    color: var(--fab-icon-color);
  }
  
  .increment-buttons {
    display: flex; 
    margin: 8px 8px 0 8px;
  }
  
  .popover-content {
    display: flex; 
    height: 100%;
    flex-direction: row
  }
  
  .scroll-parent {
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  .scroll-container {
    background: var(--f7-card-bg-color);
    overflow: auto;
    flex-grow: 1;
  }
  
  .datepicker-container {
    width: 360px;
    height: 100%;
  }
</style>