<!-- Main Framework7 App component where we pass Framework7 params -->
<App theme="md" name="My App" id="com.demoapp.test" routes={routes} darkMode={$darkmode}>
  <!-- Put login screen before the other components because it performs loading
       of credentials from storage, and we want credentials available before
      other components are mounted and make api requests that require the credentials. -->  
  <LoginScreen></LoginScreen>
  <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined"
      rel="stylesheet">

    <!-- Main view -->
    <View main masterDetailBreakpoint={700}>
    </View>
  </App>
  <script lang="ts">
    import { onMount } from 'svelte';
    import { App, View, f7ready } from 'framework7-svelte';
    import MainPage from '../pages/MainPage.svelte';
    import ProjectsPage from '../pages/ProjectsPage.svelte';
    import ProjectPage from '../pages/ProjectPage.svelte';
    import LoginScreen from '../components/LoginScreen.svelte';
    import AccessPage from '../pages/AccessPage.svelte';
    import RatesPage from '../pages/RatesPage.svelte';
    import type { Router } from 'framework7/types';
    import { setErrorToastF7 } from '../utils/errors';
    import { setNotifyToastF7 } from '../utils/notify';
    import { darkmode } from '../utils/darkmode';
    
    let routes: Router.RouteParameters[] = [
      { name: 'main', path: '/', component: MainPage },
      { name: 'projects', path: '/projects', component: ProjectsPage },
      { name: 'project', path: '/project/:projectId', component: ProjectPage },
      { name: 'access', path: '/access', component: AccessPage },
      { name: 'rates', path: '/rates', component: RatesPage }
    ];

    
    export let f7router: Router.Router;

    onMount(() => {
        f7ready((f7) => {
            f7router = f7.views.main.router;
            setErrorToastF7(f7);
            setNotifyToastF7(f7);
            f7.setColorTheme('#08A967');
            f7router.on('routeChanged', (newRoute) => {
                history.pushState({}, '', newRoute.path);
            });

            window.addEventListener('popstate', () => {
              f7router.back();
            });
        })
    })
  </script>

<style>
    :global(body) {
      --f7-page-master-detail-width: 33%;
    }

    :global(.button .icon) { margin-right: 0.2em; }
</style>