﻿<FloatingButton
        label="Routes"
        active={false}
        popoverWidth="324px"
        popoverHeight="505px">

    <div class="popover-content">
        <List ul={false}>
            <ListGroup>
                <ListItem title="Routes groeperen">
                    <Toggle slot="after" onToggleChange={(x) => toggleGroupRoutes(x)}/>
                </ListItem>
                <ListItem title="Alles tonen">
                    <Toggle slot="after" checked={allRoutesVisible} onToggleChange={(x, y) => toggleAllRoutes(x, y)}/>
                </ListItem>
            </ListGroup>
        </List>

        <hr class="divider--direction-horizontal"/>

        <div class="scroll-container">
            <div class="scroll-parent">
                <List ul={false}>
                    <ListGroup>

                        {#each insight?.Routes ?? [] as route}
                            <ListItem
                                    checkbox
                                    checkboxIcon="end"
                                    value={route.Id}
                                    title={route.Name}
                                    onChange={showRoute}
                                    checked={routeInfo[route.Id]?.hidden !== true}>
                                <Icon f7="circle_fill"
                                      style={`color: ${getLegendColorString(routeInfo[route.Id].color)};font-size: 12pt;`}
                                      slot="media"/>
                            </ListItem>
                        {/each}

                    </ListGroup>
                </List>
            </div>
        </div>
    </div>
</FloatingButton>

<script lang="ts">
    import {getLegendColorRGB, getLegendColorString} from "../../utils/style.js";
    import {Icon, List, ListGroup, ListItem, Toggle} from "framework7-svelte";
    import FloatingButton from "../FloatingButton.svelte";
    import type {IRouteInfo} from "../../utils/types";
    import type {IInsight} from "../../../../src/webapi/apitypes";
    import {createEventDispatcher, tick} from "svelte";
    import {extractRouteCore} from "../../utils/routecore";

    export let routeInfo: { [id: string]: IRouteInfo } = {};
    export let insight: IInsight | undefined;

    let ignoreNextToggleTimeout = undefined;
    let allRoutesVisible = false;

    $: {
        const visible = !Object.values(routeInfo).some(r => r.hidden === true);
        if (visible !== allRoutesVisible) {
            allRoutesVisible = visible;
            // When visibility changes because of having clicked toggles, this triggers a change event which will then
            // enable/disable everything. To prevent this, we set to ignore the next toggle.
            doIgnoreNextToggle();
        }
    }

    const eventDispatcher = createEventDispatcher();

    function showRoute(event) {
        const id = event.target.value;
        const visible = event.target.checked;
        if (allRoutesVisible && !visible) {
            doIgnoreNextToggle();
        }
        let info = routeInfo[id];
        info.hidden = !visible;
        routeInfo = routeInfo;
        eventDispatcher("selectionChanged");
    }

    const IGNORE_TIME_MS = 250;

    // Ignore the next toggle request for IGNORE_TIME_MS milliseconds. This is a poor man's solution
    // that we use because we cannot reliably detect whether a toggle event results from an explicit click
    // on the toggle component, or implicitly because another route is selected/unselected which changes the
    // toggle value. I've spent many hours on this, and it is very difficult to get right in all cases. The
    // timeout approach is not very nice, but it is simple at least.
    function doIgnoreNextToggle() {
        if (ignoreNextToggleTimeout) {
            clearTimeout(ignoreNextToggleTimeout);
        }
        ignoreNextToggleTimeout = setTimeout( () => {
            ignoreNextToggleTimeout = undefined;
        }, IGNORE_TIME_MS);
    }

    function toggleGroupRoutes(groupRoutes: boolean) {
        
        if(!groupRoutes){
            let idx = 0;
            for(const route of insight.Routes){
                routeInfo[route.Id].color = getLegendColorRGB(idx++);
            }
        }
        else{
            let routeGroups: string[] = [];
            for (const route of insight.Routes) {
                let routeGroup = extractRouteCore(route.Name);
                if (!routeGroups?.includes(routeGroup)) {
                    routeGroups.push(routeGroup);
                }
                routeInfo[route.Id].color = getLegendColorRGB(routeGroups.indexOf(routeGroup));
            }
        }

        eventDispatcher("selectionChanged");
    }

    async function toggleAllRoutes(value: boolean, q: unknown) {
        if (!!ignoreNextToggleTimeout) {
            return;
        }

        if(!value){
            for (const route of insight.Routes) {
                routeInfo[route.Id].hidden = true;
            }
        } else {
            for (const route of insight.Routes) {
                routeInfo[route.Id].hidden = false;
            }
        }
        //ignoreNextToggle = true;
        routeInfo = routeInfo;
        eventDispatcher("selectionChanged");
    }

</script>


<style lang="scss">
  .popover-content {
    display: flex;
    flex-direction: column;
    height: 100%
  }

  .scroll-parent {
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  .scroll-container {
    overflow: auto;
    flex-grow: 1;
  }
</style>