import { writable } from "svelte/store";

export const PERMISSION_VIEW_PROJECTS = writable<boolean>(false);
export const PERMISSION_MODIFY_PROJECTS = writable<boolean>(false);
export const PERMISSION_VIEW_INSIGHT = writable<boolean>(false);
export const PERMISSION_USE_INSIGHT = writable<boolean>(false);
export const PERMISSION_CONFIG_INSIGHT = writable<boolean>(false);
export const PERMISSION_VIEW_ACCESS = writable<boolean>(false);
export const PERMISSION_VIEW_RATES = writable<boolean>(false);

//TODO Share wth backend
export const storeMap = {
    'view-projects': PERMISSION_VIEW_PROJECTS,
    'modify-projects': PERMISSION_MODIFY_PROJECTS,
    'view-insight': PERMISSION_VIEW_INSIGHT,
    'use-insight': PERMISSION_USE_INSIGHT,
    'config-insight': PERMISSION_CONFIG_INSIGHT,
    'view-access': PERMISSION_VIEW_ACCESS,
    'view-rates': PERMISSION_VIEW_RATES
}