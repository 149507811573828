const DEFAULT_ZOOM: number = 12;

/**
 * Applies a given offset in meters to a polyline. That is, the line will shift the specified number of meters
 * to the right (when going in the direction of the line).
 */
export function giveOffset(polyline: [number, number][], offsetInMeters: number, zoom: number) : [number, number][] {
    if (polyline.length <= 1) {
        return polyline;
    }

    const zoomFactor = 1; //Math.pow(2, DEFAULT_ZOOM - zoom);

    const points: [number, number][] = polyline.map((p) => [deg2m(lat2y(p[0])), deg2m(lon2x(p[1]))]);
    //Typescript doesn't seem to have a vector type, so I'm converting to a vector2 type I made myself, and then converting back to a [number,number][].
    const result: [number, number][] = offsetPolyline(points.map(p => new Vector2(p[0], p[1])), offsetInMeters * zoomFactor).map(v => [v.x, v.y]);
    return result.map((p) => [y2lat(m2deg(p[0])), x2lon(m2deg(p[1]))]);;
}

const INPUT_PRECISION = 0.0000001;

// from: https://wiki.openstreetmap.org/wiki/Mercator
const RAD2DEG = 180 / Math.PI;
const PI_4 = Math.PI / 4;

/* The following functions take or return their results in degrees */

export function y2lat(y: number) { return (Math.atan(Math.exp(y / RAD2DEG)) / PI_4 - 1) * 90; }
export function x2lon(x: number) { return x; }

export function lat2y(lat: number) { return Math.log(Math.tan((lat / 90 + 1) * PI_4 )) * RAD2DEG; }
export function lon2x(lon: number) { return lon; }

// From: https://www.researchgate.net/post/How_to_convert_latitude_and_longitude_into_degree_from_meter
const METERS_PER_DEGREE = 111139;
export function deg2m(deg: number) { return deg * METERS_PER_DEGREE}
export function m2deg(deg: number) { return deg / METERS_PER_DEGREE}

function offsetPolyline(polyline: Vector2[], offset: number) : Vector2[] {
    let lastDirection: Vector2 = undefined;
    let lastPoint: Vector2 = undefined;
    let result: Vector2[] = [];

    for (let i = 0; i < polyline.length - 1; i++) {
        const thisPoint = polyline[i];
        const nextPoint = polyline[i+1];
        const direction = nextPoint.sub(thisPoint);

        //If the two points are the same, we can't calculate a normal, so we skip this point.
        if(Math.abs(direction.len()) < INPUT_PRECISION) continue;

        const normal = direction.normal();  
        const offsetPoint = thisPoint.add(normal.mul(offset));

        //If this is the first point, we can't calculate an intersection, so we just add it to the result.
        if(!lastPoint){
          lastPoint = offsetPoint;
          lastDirection = direction;
          result.push(lastPoint);
          continue;
        }
        let j = result.length - 1;
        let testPoint = lastPoint;
        let testDirection = lastDirection;

        // If the intersection is behind the last point, we need to go back until we find a point where the intersection is in front of the point.
        // I haven't tested yet what happens if no such point can be found.
        let t: number;
        while((t = calculateIntersection(testPoint, testDirection, offsetPoint, direction)) < 0 && j > 0) {
            j--;
            testPoint = result[j];
            testDirection = result[j+1].sub(result[j]);
            result.splice(-1);
        }

        // When an appropriate intersection has been found, and it's not equal to the last point, add it to the result.
        const newPoint = testPoint.add(testDirection.mul(t));
        if(Math.abs((newPoint.sub(lastPoint)).len()) < INPUT_PRECISION) continue;
        result.push(newPoint);

        lastDirection = direction;
        lastPoint = newPoint;
    }

    // Add the last point.
    result.push(polyline[polyline.length-1].add(lastDirection.normal().mul(offset)));

    return result;
}

function calculateIntersection(p1: Vector2, v1: Vector2, p2: Vector2, v2: Vector2): number {
    const normal = v2.normal();
    if(Math.abs(v1.dot(normal)) <= INPUT_PRECISION) return 0; // parallel lines
    const t = (p2.sub(p1)).dot(normal) / v1.dot(normal);
    return t;
}

// Custom Vector2 class, because typescript doesn't seem to have one.
class Vector2 {
    x: number; y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    normalize() {
        const len = Math.sqrt(this.x * this.x + this.y * this.y);
        if(len <= Number.EPSILON) return Vector2.Zero;
        return new Vector2(this.x / len, this.y / len);
    }

    add(other: Vector2) {
        return new Vector2(this.x + other.x, this.y + other.y);
    }

    sub(other: Vector2) {
        return new Vector2(this.x - other.x, this.y - other.y);
    }

    mul(scalar: number) {
        return new Vector2(this.x * scalar, this.y * scalar);
    }

    dot(other: Vector2) {
        return this.x * other.x + this.y * other.y;
    }

    normal() {
        return new Vector2(-this.y, this.x).normalize();
    }

    len() {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }


    static Zero = new Vector2(0, 0);
}