import { colorHslToRgb } from "framework7/shared/utils";

export interface IDeriveOptions {
    distance: number;
    freeFlowDuration?: number;
    typicalDuration?: number;
    actualDuration?: number;
}

export interface IIndicatorValue {
    value: number | undefined;
    color?: [number, number, number, number];
}

export interface IIndicatorData {
    Header: string[];
    Timestamp: string;
    Values: IIndicatorValue[];
}

export interface IIndicator {
    id: string;
    displayName: string;
    unit: string;
    type: IndicatorType;
    caption?: boolean;
    derive: (options: IDeriveOptions) => IIndicatorValue;
}

const KMU_FACTOR = 3.6;

export type IndicatorType = 'velocity' | 'duration' | 'factor';

export const indicators: IIndicator[] = [
    {
        id: 'base-duration',
        displayName: 'Optimale reistijd',
        caption: true,
        unit: 'sec',
        type: 'duration',
        derive(options) {
            return {
                value: options.freeFlowDuration,
                color: durationToColor(options.freeFlowDuration)
            }
        }
    },
    {
        id: 'base-velocity',
        displayName: 'Optimale snelheid',
        unit: 'km/u',
        type: 'velocity',
        derive(options) {
            if (options.freeFlowDuration) {
                const value = options.distance / options.freeFlowDuration * KMU_FACTOR;
                const color = speedToColor(value);
                return { value, color};
            }
            return { value: undefined, color: toColor([0,0,0]) };
        }
    },
    {
        id: 'typical-duration',
        displayName: 'Gebruikelijke reistijd',
        unit: 'sec',
        type: 'duration',
        caption: true,
        derive(options) {
            return {
                value: options.typicalDuration,
                color: durationToColor(options.typicalDuration ?? 0)
            }
        }
    },
    {
        id: 'typical-velocity',
        displayName: 'Gebruikelijke snelheid',
        unit: 'km/u',
        type: 'velocity',
        derive(options) {
            if (options.typicalDuration) {
                const value = options.distance / options.typicalDuration * KMU_FACTOR;
                const color = speedToColor(value);
                return { value, color};
            }
            return { value: undefined, color: toColor([0,0,0]) };
        }
    },
    {
        id: 'actual-duration',
        displayName: 'Huidige reistijd',
        unit: 'sec',
        type: 'duration',
        caption: true,
        derive(options) {
            return {
                value: options.actualDuration,
                color: durationToColor(options.actualDuration ?? 0)
            }
        }
    },
    {
        id: 'actual-velocity',
        displayName: 'Huidige snelheid',
        unit: 'km/u',
        type: 'velocity',
        derive(options) {
            if (options.actualDuration) {
                const value = options.distance / options.actualDuration * KMU_FACTOR;
                const color = speedToColor(value);
                return { value, color};
            }
            return { value: undefined, color: toColor([0,0,0]) };
        }
    },
    {
        id: 'delay-factor',
        displayName: 'Vertragingsfactor',
        unit: '%',
        type: 'factor',
        derive(options) {
            if (options.actualDuration && options.typicalDuration) {
                const value = 100 * (options.actualDuration - options.typicalDuration) / options.typicalDuration;
                const color: [number, number, number] = 
                    (value <= -10) ? [0, 1, 0] :
                    (value < 10) ? [0.7, 0.7, 0.7] :
                    (value <= 50) ? [1, 1, 0] :
                    (value <= 100) ? [1, 0.5, 0] :
                    [1, 0, 0];
                return {value, color: toColor(color)};
            }
            return {
                value: undefined,
                color: toColor([0,0,0,0])
            }
        }
    },
    {
        id: 'delay-duration',
        displayName: 'Vertragingsduur',
        unit: 'sec',
        type: 'duration',
        derive(options) {
            if (options.actualDuration && options.typicalDuration) {
                const value = options.actualDuration - options.typicalDuration;
                const color: [number, number, number] = 
                    (value <= -30) ? [0, 1, 0] :
                    (value <= 30) ? [0.7, 0.7, 0.7] :
                    (value <= 120) ? [1, 1, 0] :
                    (value <= 300) ? [1, 0.5, 0] :
                    [1, 0, 0];
                return {value, color: toColor(color)};
            }
            return {
                value: undefined,
                color: toColor([0,0,0,0])
            }
        }
    },
    {
        id: 'delay-speed',
        displayName: 'Snelheidsverschil',
        unit: 'km/h',
        type: 'velocity',
        derive(options) {
            if (options.actualDuration && options.typicalDuration && options.distance) {
                const value = (options.distance / options.actualDuration - options.distance / options.typicalDuration) * KMU_FACTOR;
                const color: [number, number, number] = 
                    (value >=  10) ? [0, 1, 0] :
                    (value >= -10) ? [0.7, 0.7, 0.7] :
                    (value >= -30) ? [1, 1, 0] :
                    (value >= -50) ? [1, 0.5, 0] :
                    [1, 0, 0];
                return {value, color: toColor(color)};
            }
            return {
                value: undefined,
                color: toColor([0,0,0,0])
            }
        }
    }
]

function toColor(color: [number, number, number, number?]): [number, number, number, number] {
    return [
        Math.round(Math.max(0, Math.min(1, color[0]))*255),
        Math.round(Math.max(0, Math.min(1, color[1]))*255),
        Math.round(Math.max(0, Math.min(1, color[2]))*255),
        Math.max(0, Math.min(1, color[3] ?? 1))
    ]
}

const HUE_RED = 0;
const HUE_ORANGE = 30;
const HUE_YELLOW = 60;
const HUE_GREEN = 105;

export function speedToColor(speed: number) {
    if(speed > 200) {
        return [255, 255, 255, 1];
    }
    if (speed < 30) {
        const f = speed / 30;
        return fractionToRgb(f, HUE_RED, HUE_ORANGE);
    }
    if (speed < 60) {
        const f = (speed - 30) / 30;
        return fractionToRgb(f, HUE_ORANGE, HUE_YELLOW)
    }
    const f = (speed - 60) / 70;
    return fractionToRgb(f, HUE_YELLOW, HUE_GREEN);
}

function durationToColor(duration: number) {
    const f = Math.min(1.0, (0.5 * (Math.log10(duration ?? 0) - 1)));
    const f2 = (f > 0.5) ? 1 : 2*f;
    const f3 = ((1-f) > 0.5) ? 1 : 2*(1-f);
    return toColor([f2, f3, 0]);
}

function fractionToRgb(fraction: number, hue0: number, hue1: number): [number, number, number, number] {
    const hue = (fraction * (hue1 - hue0)) + hue0;
    const rgb = colorHslToRgb(hue, 1.0, 0.5);
    return [rgb[0], rgb[1], rgb[2], 1];
}