import type { ITrafficSegment } from "./apitypes";
import { encode } from "html-entities";
import { COLOR_STRING_GREEN, COLOR_STRING_ORANGE, COLOR_STRING_RED, COLOR_STRING_YELLOW, colorComponentsToRgbString } from "./style";
import { speedToColor } from "./indicators";

const FACTOR_KMU = 3.6;

export interface TrafficMeasurement {
    jamFactor: number,
    speed: number,
    confidence: number
}

export interface FlowMeasurement {
    flow: number,
    speed: number
}

export interface ITrafficFlowResult {
    offset: boolean;
    color: string;
    weight: number;
    opacity: number;
    tooltip: string;
}

export interface ITrafficFlowGenerator {
    generate(segment: ITrafficSegment, segmentMeasurement: TrafficMeasurement): ITrafficFlowResult;
}

export class CoverageTrafficFlowGenerator implements ITrafficFlowGenerator {
    generate(segment: ITrafficSegment, segmentMeasurement: TrafficMeasurement): ITrafficFlowResult {
        return {
            offset: false,
            color: 'purple',
            weight: 20,
            opacity: 0.3,
            tooltip: `Here: ${encode(segment.Description)} ${encode(segment.Distance.toString())} ${encode(segment.Id)}`
        };
    }
}

export class SpeedTrafficFlowGenerator implements ITrafficFlowGenerator {
    private minConfidence: number;
    
    constructor(minConfidence: number) {
        this.minConfidence = minConfidence;
    }

    generate(segment: ITrafficSegment, segmentMeasurement: TrafficMeasurement): ITrafficFlowResult {
        if (!segmentMeasurement) return;
        if (segmentMeasurement.confidence < this.minConfidence) return;
        
        const kmu = Math.round(segmentMeasurement.speed * FACTOR_KMU);
        const color = colorComponentsToRgbString(speedToColor(kmu).slice(0,3) as [number, number, number]);
        const tooltip = `Here: ${encode(segment.Description)} - ${encode(segment.Distance.toString())} m - ${encode(kmu.toString())}km/u - Jam ${encode(segmentMeasurement.jamFactor.toString())} (0-10) - ${encode(segment.Id)} `;

        return {
            offset: true,
            color: color,
            weight: 3,
            opacity: 1,
            tooltip: tooltip
        };
    }
}

export class CongestionTrafficFlowGenerator implements ITrafficFlowGenerator {
    private minConfidence: number;
    
    constructor(minConfidence: number) {
        this.minConfidence = minConfidence;
    }

    generate(segment: ITrafficSegment, segmentMeasurement: TrafficMeasurement): ITrafficFlowResult {
        if (!segmentMeasurement) return;
        if (segmentMeasurement.confidence < this.minConfidence) return;
        
        const kmu = Math.round(segmentMeasurement.speed * FACTOR_KMU);
        const cong = segmentMeasurement.jamFactor;
        const color =
            cong >= 8.5 ? COLOR_STRING_RED :
            cong >= 7 ? COLOR_STRING_ORANGE :
            cong >= 3 ? COLOR_STRING_YELLOW :
            COLOR_STRING_GREEN;
        const tooltip = `Here: ${encode(segment.Description)} - ${encode(segment.Distance.toString())} m - ${encode(kmu.toString())}km/u - Jam ${encode(segmentMeasurement.jamFactor.toString())} (0-10)`;

        return {
            offset: true,
            color: color,
            weight: 3,
            opacity: 1,
            tooltip: tooltip
        };
    }
}