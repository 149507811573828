<Navbar>
    <NavTitle>
        <Link href="/">
            <img src={$darkmode ? logodark : logo} alt="Traffic Life Logo"/>
        </Link>
    </NavTitle>
    <NavRight>
        <AppSettings/>
    </NavRight>
</Navbar>

<script>
    import { Link, NavRight, NavTitle, Navbar, f7 } from "framework7-svelte";
    import logo from '../assets/lisa-buko.svg';
    import logodark from '../assets/lisa-buko-dark.svg';
    import { darkmode } from "../utils/darkmode";
    import AppSettings from "./AppSettings.svelte";
    
</script>
