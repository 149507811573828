<div class="fb-container">
    <slot />
    <div class="fb-container-right">
      <slot name="right"/>
    </div>
</div>

<style lang="scss">
  .fb-container {
    position: absolute; 
    top: calc(12px + var(--f7-navbar-height));
    z-index: 1000;
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 24px;
  }

  .fb-container-right {
    display: flex;
    gap: 24px;
    margin-left: auto;
  }

</style>