<LoginScreen
    id="login-screen"
    opened={opened}
    onLoginScreenClosed={() => {
      opened = false;
    }}
  >
    <Page loginScreen>
      <LoginScreenTitle>{$credentials ? 'Uitloggen' : 'Inloggen'}</LoginScreenTitle>
      {#if $credentials}
      <List form>
        <ListButton onClick={signOut}>Uitloggen</ListButton>
        <ListButton loginScreenClose="#login-screen">Terug</ListButton>
      </List>
      {:else}
      <List form>
        <ListInput
          label="Gebruikersnaam"
          type="text"
          placeholder="Je gebruikersnaam"
          value={username}
          onInput={(e) => (username = e.target.value)}
        />
        <ListInput
          label="Password"
          type="password"
          placeholder="Your password"
          value={password}
          onInput={(e) => (password = e.target.value)}
        />
      </List>
      <List inset>
        <ListButton class={!username ? 'disabled' : undefined} onClick={signIn}>Inloggen</ListButton>
      </List>
      <BlockFooter>
      </BlockFooter>
      {/if}
    </Page>
  </LoginScreen>

<script lang="ts">
    import { LoginScreen, Page, List, LoginScreenTitle, ListInput, BlockFooter, ListButton, f7 } from "framework7-svelte";
    import { credentials, doSignOut, type ICredentials } from "../utils/credentials";
    import { storeMap as permissionStoreMap } from "../utils/permissions";
    import { onMount } from "svelte";
    import { makeApiCall } from "../utils/api";

    let username: string;
    let password: string;

    let opened = false;

    onMount(async () => {
        loadCredentials();

        const c = $credentials;
        if (c) {
          username = c.username;
          password = c.password;
          await loadPermissions();
        }
        //opened = (!c);
    });

    async function signIn() {
        const c: ICredentials = { username, password };
        const str = JSON.stringify(c);
        localStorage.setItem('credentials', str);
        credentials.set(c);
        if($credentials) {
          await loadPermissions();
        }
        f7.loginScreen.close();
    }

    function signOut() {
        doSignOut();
        password = undefined;
    }

    function loadCredentials() {
      const str = localStorage.getItem('credentials');
      if (str) {
        const c = JSON.parse(str) as ICredentials;
        credentials.set(c);
      }
    }

    async function loadPermissions() {
      const userPermissions = (await makeApiCall('permissions/get')).Permissions;
      for (const permission of userPermissions) {
        permissionStoreMap[permission].set(true);
      }
    }

</script>