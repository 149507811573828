import type Framework7 from "framework7/types";

let toastf7: Framework7 | undefined = undefined;

export function setErrorToastF7(f7: Framework7) {
    toastf7 = f7;
    window.onerror = (_event, _source, _lineno, _colno, error) => {
        handleError(error);
        return true;
    }
    window.onunhandledrejection = function(ev: PromiseRejectionEvent) {
        handleError(ev.reason);
    }
}

export function handleError(e: unknown) {
    console.log(e);
    const t = toastf7?.toast.create({
        closeButton: true,
        horizontalPosition: 'center',
        position: 'top',
        text: e.toString()
    });
    t.open();
}

