<div class="margin">
    <div class="title">Meetintervallen</div>
    <List>
        {#each items as item}        
            <ListInput type="select" value={values[item]} label={item} on:input={(e) => setValue(item, e)} outline>
                <option value="0">Niet meten</option>
                <option value="1">Iedere 1 minuut</option>
                <option value="2">Iedere 2 minuten</option>
                <option value="5">Iedere 5 minuten</option>
                <option value="10">Iedere 10 minuten</option>
                <option value="15">Iedere 15 minuten</option>
                <option value="20">Iedere 20 minuten</option>
                <option value="30">Iedere 30 minuten</option>
                <option value="60">Iedere 60 minuten</option>
            </ListInput>
        {/each}

        <ListInput type="select" value={hereFunctionalClass?.toString()} label="Here functionele klasse" on:input={(v) => hereFunctionalClass=parseInt(v.detail[0].srcElement.value)} outline>
            <option value="1">Klasse 1 (Hoofdwegen)</option>
            <option value="2">Klasse 2</option>
            <option value="3">Klasse 3</option>
            <option value="4">Klasse 4</option>
            <option value="5">Klasse 5 (Kleine wegen)</option>
        </ListInput>

        <ListInput type="select" value={mapboxZoomLevel?.toString()} label="Mapbox zoom level" on:input={(v) => mapboxZoomLevel=parseInt(v.detail[0].srcElement.value)} outline>
            <option value="11">11: Verbindingswegen</option>
            <option value="12">12: Grote wegen in stad/dorp</option>
            <option value="13">13: Grotere wegen in de buurt</option>
        </ListInput>

        <ListItem>
            <Button popupClose on:click={commit} fill>Opslaan</Button>
            <Button popupClose>Annuleren</Button>
        </ListItem>
    </List>
</div>
<script lang="ts">
import { List, ListInput, Page, ListItem, Button } from "framework7-svelte";
import type { IMeasurementIntervals } from "../utils/apitypes";
import { createEventDispatcher } from "svelte";

const items = ['here-routing', 'here-traffic', 'ndw-intensity', 'mapbox-congestion'];
let values: {[key: string]: string} = {};

const dispatch = createEventDispatcher();

export let intervals: IMeasurementIntervals;
export let mapboxZoomLevel: number;
export let hereFunctionalClass: number;

$:loadValues(intervals);

function loadValues(intervals: IMeasurementIntervals) {
    values = {
        ['here-routing']: (intervals["here-routing"] ?? 0).toString(),
        ['here-traffic']: (intervals["here-traffic"] ?? 0).toString(),
        ['ndw-intensity']: (intervals["ndw-intensity"] ?? 0).toString(),
        ['mapbox-congestion']: (intervals["mapbox-congestion"] ?? 0).toString()
    }
}

function setValue(item: string, e: CustomEvent) {
    const v = e.detail[0].srcElement.value;
    values[item] = v;
}

async function commit() {
    const intervals: IMeasurementIntervals = {
        ['here-routing']: parseInt(values['here-routing']),
        ['here-traffic']: parseInt(values['here-traffic']),
        ['ndw-intensity']: parseInt(values['ndw-intensity']),
        ['mapbox-congestion']: parseInt(values['mapbox-congestion'])
    }
    dispatch('changed', {intervals, mapboxZoomLevel, hereFunctionalClass});
}
</script>