<Page>
    <Header/>
    <Block>
        {#if !$PERMISSION_VIEW_RATES}
        <p>You are not allowed to view this page</p>
        {:else}
        <p>Your download will start automatically</p>
        {/if}
    </Block>
</Page>

<script lang="ts">
    import { Block, Page } from "framework7-svelte";
    import { onMount } from "svelte";
    import Header from '../components/Header.svelte';
    import { makeApiCall } from "../utils/api";
    import type { IProjectInfo, IRates, IRatesItem } from "../utils/apitypes";
    import { PERMISSION_VIEW_RATES } from "../utils/permissions";

    interface IProjectInfoList {
        Items: IProjectInfo[];
    }

    onMount(async () => {
        if(!$PERMISSION_VIEW_RATES) return;

        const projects = await makeApiCall('projects/get') as IProjectInfoList;
        const rates = {};
        for (const project of projects.Items) {
            rates[project.Id] = await makeApiCall('project/insight/rates/get', { Id: project.Id });
        }
        let csv: string[] = [];
        csv.push('Project,Month,Collector,Rate,Price');
        for (const project of projects.Items) {
            const projectRates: IRates = rates[project.Id];
            let calls = {};
            for (const collector of projectRates.Collectors) {
                const byMonth = {};
                for (const date of collector.Rates) {
                    const month = date.Date.slice(0, 7);
                    if(!byMonth[month]) {
                        byMonth[month] = 0;
                    }
                    byMonth[month] += date.Rate;
                }
                for (let month in byMonth) {
                    if(!calls[month]) {
                        calls[month] = {};
                    }
                    calls[month][collector.Collector] = byMonth[month];
                }
            }
            for (let month in calls) {
                const monthCalls = calls[month];
                let totalCalls = 0; 
                let totalPrice = 0;
                for (let collector in monthCalls) {
                    const rate = monthCalls[collector];
                    const price = roundPrice(rate * projectRates.Collectors.find(c => c.Collector === collector).Price);
                    totalCalls += rate;
                    totalPrice += price;
                    csv.push(`${project.Name},${month},${collector},${rate},${price}`);
                }
                csv.push(`${project.Name},${month},${'total'},${totalCalls},${roundPrice(totalPrice)}`);
            }
        }

        exportToFile(csv.join('\n'), 'text/csv', 'rates.csv');
    });

    function exportToFile(data: string, contentType: string, fileName: string) {
        const blob = new Blob([data], {type: contentType});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
    }

    function roundPrice(price: number) {
        return Math.round(price * Math.pow(10, 4)) / Math.pow(10, 4);
    }
</script>