﻿<Page>
    <Navbar>
        <NavLeft>
            <Link href="/">
                <img src={logo} alt="Traffic Life Logo">
            </Link>
            <span class="project-name">
                {projectName}
            </span>
        </NavLeft>
        <NavRight>
            <Link on:click={handleClose}>Sluiten</Link>
        </NavRight>
    </Navbar>

    <div class="main-container">
        <div class="map-container">
            <RouteEditorMap
                    visible="{visible}"
                    center="{mapCenter}"
                    zoom="{13}"
                    gates="{gates}"
                    routes="{routes}"
                    selectedRoute="{selectedRoute}"
                    selectedGate="{selectedGate}"
                    highlightedGate={highlightedGate}
                    isAddingGate={isAddingGate}
                    on:latlng={handleLatLng}
                    on:gate={handleGateSelected}
                    />
        </div>
        
        <div class="editor-container">
            <RouteEditor 
                    bind:routes="{routes}" 
                    bind:gates="{gates}"
                    bind:selectedRoute="{selectedRoute}"
                    bind:selectedGate="{selectedGate}"
                    bind:highlightedGate="{highlightedGate}"
                    isSaving="{isSaving}"
                    hasChanges={changed}
                    isAddingGate={isAddingGate}
                    on:addRoute={handleAddRoute}
                    on:addGate={handleAddGate}
                    on:save={saveInsight}
                    on:changed={markChanged}
                    on:close={handleClose}
                    on:editRouteName={() => handleEditRouteName(selectedRoute)}
                    on:editGateName={() => handleEditGateName(selectedGate)}
                    on:deleteRoute={() => handleDeleteRoute(selectedRoute)}
                    on:deleteGate={() => handleDeleteGateFromRoute(selectedGate, selectedRoute)}
                    on:editGateLocation={() => handleEditGateLocation(selectedGate)}
                    />
        </div>
    </div>
    
</Page>

<script lang="ts">
    import {Link, NavRight, Navbar, Page, NavLeft, f7 } from "framework7-svelte";
    import type {
        IGate,
        IGateDefinition,
        IInsight,
        IInsightDefinition,
        IRoute,
        IRouteDefinition
    } from "../../../../lib/webapi/apitypes";
    import 'leaflet/dist/leaflet.css';
    import type {LatLng, LatLngExpression} from "leaflet";
    import logo from "../../assets/lisa-buko.svg";
    import RouteEditor from "./RouteEditor.svelte";
    import RouteEditorMap from "./RouteEditorMap.svelte";
    import {makeApiCall} from "../../utils/api";
    import {createEventDispatcher, tick} from "svelte";
    import { notify } from "../../utils/notify";
    import type { Toast } from "framework7/types";
    
    export let visible = false;
    export let insight: IInsight;
    export let projectName: string;

    const eventDispatcher = createEventDispatcher();
    
    let isSaving = false;
    
    let routes: IRoute[];
    let gates: IGate[];
    let changed = false;

    $: if(!routes && !gates){
        routes = JSON.parse(JSON.stringify(insight.Routes));
        gates = JSON.parse(JSON.stringify(insight.Gates));
    }
    
    let selectedRoute: IRoute | null;
    let selectedGate: IGate | null;
    let highlightedGate: IGate | null;
    let isAddingGate = false;

    let onLatLng: (value: LatLng) => void;
    let onGateSelected: (id: string) => void;

    let gateToast: Toast.Toast;

    const VORMER_ZWOLLE: LatLngExpression = [52.50846510314036, 6.123970270747168];
    let mapCenter = VORMER_ZWOLLE;

    async function saveInsight() {
        isSaving = true;
        await makeApiCall("project/insight/put", {
            Gates: gates.map(gate => ({Id: gate.Id, Name: gate.Name, Coord: gate.Coord} as IGateDefinition)),
            Routes: routes.map(route => ({Id: route.Id, Name: route.Name, Gates: route.Gates.map(gate => gate)} as IRouteDefinition)),
            ProjectId: insight.ProjectId,
        } as IInsightDefinition);
        eventDispatcher("insightUpdated");
        changed = false;
        isSaving = false;
    }

    function handleLatLng(latlngEvent) {
        if (onLatLng) {
            const handler = onLatLng;
            onLatLng = undefined;
            onGateSelected = undefined;
            handler(latlngEvent.detail);
        }
    }

    function handleGateSelected(gateEvent) {
        if (onGateSelected) {
            const handler = onGateSelected;
            onLatLng = undefined;
            onGateSelected = undefined;
            handler(gateEvent.detail);
        }
    }

    async function handleClose() {
        if (changed) {
            f7.dialog.confirm('Niet alle wijzigingen zijn opgeslagen. Doorgaan zonder opslaan?', 'Doorgaan zonder opslaan?', () => f7.popup.close())
        } else {
            f7.popup.close();
        }
    }

    function markChanged() {
        changed = true;
    }

    function handleDeleteRoute(selectedRoute: IRoute) {
        const soloGates = selectedRoute.Gates.filter((x) => routes.filter((y) => y.Gates.includes(x)).length === 1);
        let dialogText = 'Weet je zeker dat je deze route wilt verwijderen? ';
        if (soloGates.length > 0) {
            if (soloGates.length === 1) {
                dialogText += 'Gate ' + soloGates[0] + ' wordt';
            } else {
                dialogText += `Gates ${soloGates.reduce((p, c) => `${p}, ${c}`)} worden`;
            }
            dialogText += ' ook verwijderd, omdat die niet in andere routes gebruikt';
            dialogText += (soloGates.length === 1) ? ' wordt' : ' worden';
            dialogText += '.';
        }

        f7.dialog.confirm(dialogText, 'Verwijderen?', () => {
            const selectedRouteLocal = selectedRoute;
            selectedRoute = null;
            routes = routes.filter((x) => x !== selectedRouteLocal);
            gates = gates.filter((x) => !soloGates.includes(x.Id));
            markChanged();
        });
    }

    function handleDeleteGateFromRoute(selectedGate: IGate, selectedRoute: IRoute) {
        const isOnlyRoute = routes.filter((x) => x.Gates.includes(selectedGate.Id)).length === 1;
        f7.dialog.confirm('Weet je zeker dat je deze gate uit de route wilt verwijderen?'
                + isOnlyRoute ? ' Omdat geen andere routes deze gate gebruiken, wordt de gate zelf ook verwijderd' : '', 'Verwijderen?', () => {
            selectedRoute.Gates = selectedRoute.Gates.filter((x) => x !== selectedGate.Id);
            markChanged();
            selectedGate = null;
            routes = routes;

            if (isOnlyRoute) {
                gates = gates.filter((x) => x !== selectedGate);
            }
        });
    }

    function handleEditRouteName(selectedRoute: IRoute) {
        f7.dialog.prompt('Wat is de nieuwe naam van de route?', 'Route ' + selectedRoute.Name, (name) => {
            if (routes.find((x) => x.Id === name && x.Id !== selectedRoute.Id)) {
                notify('Er bestaat al een route met deze naam. De route is niet toegevoegd.');
                return;
            }
            selectedRoute.Name = name;
            selectedRoute = selectedRoute;
            routes = routes;
            markChanged();
        }, () => {}, selectedRoute.Name);
    }

    function handleAddRoute() {
        f7.dialog.prompt('Wat is de naam van de nieuwe route?', 'Nieuwe route', (name) => {
            if (routes.find((x) => x.Id === name)) {
                notify('Er bestaat al een route met deze naam. De route is niet toegevoegd.');
                return;
            }

            const newRoute: IRoute = {
                Id: name,
                Name: name,
                Gates: [],
                Sections: []
            };
            routes.push(newRoute);
            routes = routes;
            notify(`Route ${name} is toegevoegd`);
            markChanged();
            selectedGate = null;
            selectedRoute = newRoute;
        });
    }

    function handleEditGateName(selectedGate: IGate) {
        f7.dialog.prompt('Wat is de nieuwe naam van de gate?', 'Gate ' + selectedGate.Name, (name) => {
            if (gates.find((x) => x.Id === name && x.Id !== selectedGate.Id)) {
                notify('Er bestaat al een gate met deze naam. De gate is niet toegevoegd.');
                return;
            }
            selectedGate.Name = name;
            selectedGate = selectedGate;
            gates = gates;
            markChanged();
        }, () => {}, selectedGate.Name);
    }

    function handleAddGate() {
        isAddingGate = true;
        const route = selectedRoute;
        const gate = selectedGate;
        
        if (!route) {
            return;
        }

        onLatLng = (latlng) => {
            gateToast.close();
            f7.dialog.prompt('Wat is de naam van de gate?', 'Nieuwe gate', (name) => {
                if (gates.find((x) => x.Id === name)) {
                    notify('Er bestaat al een gate met deze naam. De gate is niet toegevoegd.');
                    isAddingGate = false;
                   return;
                }
                const newGate: IGate = {
                    Id: name,
                    Name: name,
                    Coord: {
                        Lat: latlng.lat,
                        Lng: latlng.lng
                    }
                };
                gates.push(newGate);
                if (gate && route) {
                    const idx = route.Gates.indexOf(gate.Id);
                    route.Gates.splice(idx+1, 0, name);
                } else {
                    route.Gates.push(name);
                }
                notify(`Gate ${name} is toegevoegd aan route ${route.Name}`);
                markChanged();
                selectedGate = newGate;
                gates = gates;
                routes = routes;
                isAddingGate = false;
            }, () => {
                isAddingGate = false;
            });
        }
    
        onGateSelected = (id) => {
            gateToast.close();
            if (gate && route) {
                const idx = route.Gates.indexOf(gate.Id);
                route.Gates.splice(idx+1, 0, id);
            } else {
                route.Gates.push(id);
            }
            selectedGate = gates.find((x) => x.Id === id);
            markChanged();
            gates = gates;
            routes = routes;
            isAddingGate = false;
        }

        if (!gateToast) {
            gateToast = f7.toast.create({
            position: 'bottom',
            closeButton: true,
            horizontalPosition: 'center',
            closeButtonText: 'Annuleer',
            text: 'Plaats de gate op de kaart',
            on: {
                close() {
                    if (isAddingGate) {
                        isAddingGate = false;
                    }
                },
            } 
            });
        }

        gateToast.open();
    }

    function handleEditGateLocation(gate: IGate) {
        isAddingGate = true;
        const route = selectedRoute;
        
        if (!route) {
            return;
        }

        onLatLng = (latlng) => {
            gateToast.close();
            gate.Coord = {
                Lat: latlng.lat,
                Lng: latlng.lng
            };
            markChanged();
            gates = gates;
            routes = routes;
            isAddingGate = false;
        }
    
        onGateSelected = (id) => {
            gateToast.close();
            const existingGate = gates.find((x) => x.Id === id);
            if (existingGate) {
                gate.Coord = {
                    Lat: existingGate.Coord.Lat,
                    Lng: existingGate.Coord.Lng
                };
                markChanged();
                gates = gates;
                routes = routes;
            }
            isAddingGate = false;
        }

        if (!gateToast) {
            gateToast = f7.toast.create({
            position: 'bottom',
            closeButton: true,
            horizontalPosition: 'center',
            closeButtonText: 'Annuleer',
            text: 'Plaats de gate op de kaart',
            on: {
                close() {
                    if (isAddingGate) {
                        isAddingGate = false;
                    }
                },
            } 
            });
        }

        gateToast.open();
    }

    
</script>

<style lang="scss">
    @import "../../assets/scss/colors";
  
    .main-container {
      width: 100%; 
      height: 100%; 
      display: flex; 
      flex-direction: row
    }
    
    .map-container {
        flex-grow: 6;
    }
    
    .editor-container {
      width: 376px; 
      padding: 12px 12px 0 12px; 
      box-shadow: 1px 1px 3px #{$box-shadow-color}; 
      background: var(--f7-card-bg-color); 
      z-index: 801
    }
</style>