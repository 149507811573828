import { writable } from "svelte/store";
import { storeMap } from "./permissions";

export interface ICredentials {
    username: string;
    password: string;
}

export const credentials = writable<ICredentials | undefined>();

export function doSignOut() {
    credentials.set(undefined);
    localStorage.removeItem('credentials');
    for (const store of Object.values(storeMap)) {
        store.set(false);
    }
}