﻿<div id="calendar-container"/>

<script lang="ts">
    import {f7} from 'framework7-svelte';
    import type {Calendar} from "framework7/framework7-types";
    import { createEventDispatcher } from 'svelte';

    export let enabledTimestamps: Date[] = [];
    export let selectedTimestamp: Date;

    const dispatch = createEventDispatcher<{'selected': Date}>();

    let calendar: Calendar.Calendar;

    $: distinctDates = enabledTimestamps
        .map((date) => new Date(new Date(date).setHours(0, 0, 0, 0)))
        .filter((date, index, self) => self.indexOf(date) === index);

    $: if (distinctDates.length > 0) {
        const startDate = new Date(distinctDates[distinctDates.length - 1]);
        startDate.setDate(startDate.getDate() - 1);
        const endDate = new Date(distinctDates[0]);

        if (!calendar) {
            calendar = createCalendar(startDate, endDate, extractDisabledDates(startDate, endDate, distinctDates));
            const lastDate = distinctDates[distinctDates.length - 1];
            calendar.setYearMonth(lastDate.getFullYear(), lastDate.getMonth(), 0);
        } else {
            calendar.params.minDate = startDate;
            calendar.params.maxDate = endDate;
            calendar.update();
        }
    }

    $: if (selectedTimestamp && calendar) {
        calendar.setValue([new Date(selectedTimestamp)]);
        calendar.setYearMonth(selectedTimestamp.getFullYear(), selectedTimestamp.getMonth(), 0);
        console.log('selectedTimestamp: ', selectedTimestamp);
        calendar.update();
    }

    function createCalendar(startDate: Date, endDate: Date, disabledDates: Calendar.DateRange): Calendar.Calendar {
        const monthNames = [
            'Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli',
            'Augustus', 'September', 'Oktober', 'November', 'December'];

        return f7.calendar.create({
            containerEl: '#calendar-container',
            minDate: startDate,
            maxDate: endDate,
            disabled: disabledDates,
            value: [endDate],
            dayNames: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
            dayNamesShort: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vrij', 'Za'],
            touchMove: false,
            renderToolbar: function () {
                return `<div class="toolbar calendar-custom-toolbar no-shadow">
                            <div class="toolbar-inner">
                                <div class="left">
                                    <a  class="link icon-only">
                                        <i class="icon icon-back"></i>
                                    </a>
                                </div>
                                <div class="center"></div>
                                <div class="right">
                                    <a  class="link icon-only">
                                        <i class="icon icon-forward"></i>
                                    </a>
                                </div>
                            </div>
                        </div>`;
            },
            on: {
                init: function (c) {
                    f7.$('.calendar-custom-toolbar .center').text(monthNames[c.currentMonth] + ', ' + c.currentYear);
                    f7.$('.calendar-custom-toolbar .left .link').on('click', function () {
                        calendar.prevMonth(1);
                    });
                    f7.$('.calendar-custom-toolbar .right .link').on('click', function () {
                        calendar.nextMonth(1);
                    });
                },
                monthYearChangeStart: function (c) {
                    f7.$('.calendar-custom-toolbar .center').text(monthNames[c.currentMonth] + ', ' + c.currentYear);
                },
                dayClick(c, dayEl, year, month, day) {
                    const pickedDate = new Date(year, month, day);
                    dispatch('selected', pickedDate)
                },
            }
        });
    }

    function extractDisabledDates(startDate: Date, endDate: Date, dates: Date[]): Calendar.DateRange {
        const disabledDates = [];
        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
            if (!dates.some(date => date.toDateString() === d.toDateString())) {
                disabledDates.push({date: new Date(d)});
            }
        }
        return disabledDates;
    }
</script>