<Page>
    <Navbar>
        <NavLeft class="col-33">
            <Link href="/">
                <img src={$darkmode ? logodark : logo} alt="Traffic Life Logo"/>
            </Link>
            <span class="project-name">
                {loaded?.name ?? 'Nieuw project'}
            </span>
        </NavLeft>
        <NavTitle class="col-33">
            <Toolbar tabbar top>
                <Link tabLink={`#tab-general-${projectId}`} tabLinkActive={isNew}>Algemeen</Link>
                {#if !isNew}
                    <Link tabLink={`#tab-insight-${projectId}`} tabLinkActive>Inzichten</Link>
                {/if}
            </Toolbar>
        </NavTitle>
        <NavRight>
            <Link href="/projects">Projecten</Link>
            <hr class="divider--direction-vertical">
            <AppSettings/>
        </NavRight>
    </Navbar>

    <Tabs>
        <Tab id={`tab-general-${projectId}`} tabActive={isNew}>
            {#if !isNew}
            <ActionBar>
                {#if $PERMISSION_MODIFY_PROJECTS}
                <Button outline class="display-inline-flex padding-horizontal" actionsOpen="#confirm-delete" iconMaterial="delete">Project verwijderen</Button>
                {/if}
            </ActionBar>
            {/if}
            
            <Block style="max-width: 800px">
                <List inlineLabels noHairlinesMd>
                    <ListInput label="Naam" type="text" outline clearButton value={actual.name} onInput={updateName} disabled={!$PERMISSION_MODIFY_PROJECTS}></ListInput>
                    {#if $PERMISSION_MODIFY_PROJECTS}
                    <ListItem>
                        <Button fill on:click={doSubmit} disabled={isnotchanged}>Opslaan</Button>
                        <Button on:click={doRevert} disabled={isnotchanged}>Herstellen</Button>
                    </ListItem>
                    {/if}
                </List>
            </Block>                    
        </Tab>
        <Tab id={`tab-insight-${projectId}`} tabActive={!isNew} onTabShow={() => insightVisible = true} onTabHide={() => insightVisible = false}>
            {#if !isNew}
            <InsightFrame 
                    projectId={projectId} 
                    projectName="{loaded?.name}"
                    visible={insightVisible} />
            {/if}
        </Tab>
    </Tabs>

    <Actions id="confirm-delete">
        <ActionsGroup>
          <ActionsLabel>Zeker weten? Dit project en alle onderliggende data wordt verwijderd. Dit kan niet meer ongegaan gemaakt worden.</ActionsLabel>
          <ActionsButton bold on:click={doDelete}>Verwijderen</ActionsButton>
          <ActionsButton color="red">Annuleren</ActionsButton>
        </ActionsGroup>
    </Actions>
    
</Page>

<script lang="ts">
    import { Page, Block, Link, List, ListInput, ListItem, Button, Tabs, Tab, Toolbar, Actions, ActionsLabel, ActionsButton, ActionsGroup, Navbar, NavTitle, NavRight, NavLeft } from 'framework7-svelte';
    import { onMount } from 'svelte';
    import { navparts } from '../utils/navparts';
    import type { Router } from 'framework7/types';
    import type { IProjectInfo } from '../utils/apitypes';
    import ActionBar from '../components/ActionBar.svelte';
    import InsightFrame from '../components/InsightFrame.svelte';
    import { makeApiCall } from '../utils/api';
    import { PERMISSION_VIEW_PROJECTS, PERMISSION_MODIFY_PROJECTS } from '../utils/permissions';
    import logo from '../assets/lisa-buko.svg';
    import logodark from '../assets/lisa-buko-dark.svg';
    import { darkmode, setDarkMode } from '../utils/darkmode';
    import AppSettings from '../components/AppSettings.svelte';

    export let projectId : string;
    export let f7router : Router.Router | undefined;

    interface IProject {
        id?: string;
        name?: string;
    }

    let insightVisible = true;
    let loaded: IProject | undefined;
    let modified: IProject = {};

    $: actual = merge(loaded, modified);
    $: isnotchanged = !changed(merge(loaded, {}), actual);
    $: isNew = projectId === 'new';
    
    onMount(async () => {
        if (projectId !== 'new') {
            const data: IProjectInfo = {
                Id: projectId
            };
            
            const infoData = await makeApiCall('project/get', data) as IProjectInfo;
            loaded = {
                id: infoData.Id,
                name: infoData.Name
            };

            navparts.set([
                { name: 'Projecten', href: '/projects'},
                { name: loaded.name, href: `/project/${projectId}`}
            ]);
        } else {
            navparts.set([
                { name: 'Projecten', href: '/projects'},
                { name: `Nieuw project`, href: `/project/${projectId}`}
            ]);
        }
    });

    function updateName(e: CustomEvent) {
        const value = (e.target as any).value;
        modified.name = value;
    }

    async function doSubmit() {
        if(!$PERMISSION_VIEW_PROJECTS) {
            throw new Error('U heeft geen rechten om dit project te wijzigen');
        }

        const data: IProjectInfo = {
            Id: projectId === 'new' ? '' : projectId,
            Name: modified.name
        };

        const respData = await makeApiCall('project/put', data) as IProjectInfo;

        modified = {};
        
        if (projectId === 'new') {
            f7router?.navigate(`/project/${respData.Id}`);
        } else {

            loaded = {
                id: respData.Id,
                name: respData.Name
            };
        }
        return false;
    }

    function doRevert() {
        modified = null;
    }

    async function doDelete() {
        if(!$PERMISSION_VIEW_PROJECTS) {
            throw new Error('U heeft geen rechten om dit project te verwijderen');
        }
        const data: IProjectInfo = {
            Id: projectId
        };
        await fetch('/api/project/delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        f7router?.back(undefined, {force: true});
    }

    function merge(l: IProject | undefined, m: IProject | undefined ): IProject {
        return {
            id: m?.id ?? l?.id ?? '',
            name: m?.name ?? l?.name ?? ''
        };
    }

    function changed(ref: IProject | undefined, modified: IProject | undefined): boolean {
        return ( (modified?.id !== ref?.id) || (modified?.name !== ref?.name) );
    }

</script>

<style>
    :global(.col-33) { width: 33%; }

    .project-name {
        color: var(--proj-secondary)
    }
</style>