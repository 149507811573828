<Page>
    <Header/>
    
    <Block>
        {#if items}
        <div class="card data-table">
        <table>
            <thead>
            <tr>
                <th>
                    <span class="table-head-label">Username</span>
                </th>
                <th>
                    <span class="table-head-label">Moment</span>
                </th>
                <th>
                    <span class="table-head-label">DeviceId</span>
                </th>
                <th>
                    <span class="table-head-label">UserAgent</span>
                </th>
            </tr>
            </thead>
            <tbody>
            {#each items as item, i}
            <tr>
                <td>{item.UserName}</td>
                <td>{item.Time}</td>
                <td>{item.DeviceId}</td>
                <td>{item.UserAgent}</td>
            </tr>
            {/each}
            </tbody>
        </table>
        </div>
        {/if}        
    </Block>
</Page>

<script lang="ts">
    import { Page, Block } from 'framework7-svelte';
    import { onMount } from 'svelte';
    import type { IAccessItem, IAccessResult } from '../utils/apitypes';
    import { navparts } from '../utils/navparts';
    import { makeApiCall } from '../utils/api';
    import Header from '../components/Header.svelte';
    import { PERMISSION_VIEW_ACCESS } from '../utils/permissions';

    let items: IAccessItem[] = [];

    onMount(async () => {
        if (!$PERMISSION_VIEW_ACCESS) {
            return;
        }
        const infoData = await makeApiCall('accesslog/get') as IAccessResult;
        
        items = [];
        for (const item of infoData.Items) {
            items.push(item);
        }
        items = items;

        navparts.set([
            { name: 'Toegangslog', href: '/access'},
        ]);
    });
</script>