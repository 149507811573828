import { f7 } from "framework7-svelte";
import { writable } from "svelte/store";

export const darkmode = writable<boolean>(localStorage.getItem('DarkMode') === 'true');

export function setDarkMode(value: boolean) {
    darkmode.set(value);
    f7?.setDarkMode(value);
    localStorage.setItem('DarkMode', value ? 'true' : 'false');
}
