<Page>
    <Navbar>
      <NavLeft>
        <img class="logo" src={logo} alt="logo"/>
        <span>Routegrafiek</span>
      </NavLeft>  
      <NavTitle>
      </NavTitle>
      <NavRight>
        <Link popupClose>Sluiten</Link>
      </NavRight>
    </Navbar>
    <Block>
        <Input label="Meetwaarde" type="select" bind:value={indicatorId}>
          {#each indicators as ind}
              <option value={ind.id}>{ind.displayName} ({ind.unit})</option>          
          {/each}
        </Input>
        <Range min={0} max={10} step={.0001} bind:value={logZoomLevel} onRangeChanged={onZoomChange}></Range>
    </Block>
    <div style="width: 100%; height: 80%; position: relative;">
        <canvas bind:this="{chartContainer}"/>
    </div>
</Page>

<script lang="ts">
    import { onMount } from 'svelte';
    import Chart, { type Point } from 'chart.js/auto';
    import zoomPlugin from 'chartjs-plugin-zoom';
    import { Block, Link, Input, NavRight, Navbar, Page, Range, f7ready, NavLeft, NavTitle } from 'framework7-svelte';
    import type { IInsight, IMeasurement } from '../utils/apitypes';
    import { indicators, type IDeriveOptions } from '../utils/indicators';
    import { formatDuration } from '../utils/style';
    import type Framework7 from 'framework7/types';
    import logo from '../assets/lisa-buko.svg';
    import type { IRouteInfo } from '../utils/types';

    let chartContainer: HTMLCanvasElement;
    let chart: Chart;
    let chartData: ChartData;
    let indicatorId: string = 'delay-factor';
    let fr7: Framework7;
    let logZoomLevel: number = 0;

    $: indicator = indicators.find((x) => x.id === indicatorId);
    $: updateChartImpl(measurementSummaryList, indicatorId, chartData); 

    export let visible: boolean;
    export let insight: IInsight;
    export let allTimestamps: string[];
    export let measurementSummaryList: IMeasurement[];
    export let measurementSummaryProvider: (timestamps: string[]) => void;

    $: fetchMeasurements(allTimestamps, visible);
    export let routeInfo: {[id: string]: IRouteInfo};

    onMount(async () => {
        f7ready((f7) => {
            fr7 = f7;
        });
        chartData = {
            labels: [],
            datasets: []
        }
        chart = new Chart(chartContainer, {
            type: 'line',
            data: chartData,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        suggestedMin: 0,
                        suggestedMax: 0,
                        ticks: {
                            callback: function(value) {
                                if ((indicator?.type === 'duration')) {
                                    return formatDuration(value as number);
                                } else {
                                    return value;
                                }
                            }
                        }
                    },
                    x: {
                        afterDataLimits: handleZoomChange
                    }
                },
                interaction: {
                    intersect: false,
                    mode: 'nearest',
                    axis: 'x'
                },
                elements: {
                    point: {
                        radius: 0
                    }
                },
                plugins: {
                    zoom: {
                        pan: {
                            enabled: true,
                            mode: 'x'
                        },
                        zoom: {
                            wheel: {
                                enabled: true,
                            },
                            pinch: {
                                enabled: true
                            },
                            mode: 'x'
                        },
                    },
                    legend: {
                        display: true,
                        labels: {
                            boxWidth: 16,
                            boxHeight: 16,
                            padding: 30
                        }
                    },
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const label = context.dataset.label || ''
                                if (context.parsed.y !== null) {
                                    const value = ((indicator?.type === 'duration')) ? formatDuration(context.parsed.y) : `${context.parsed.y.toFixed(0)} ${indicator.unit}`;
                                    return `${label}: ${value}`;
                                } else {
                                    return label;
                                }
                            }
                        }
                    }
                }
            },
            plugins: [zoomPlugin],
        });
    });
    
    async function updateChartImpl(measurementSummaryList, indicatorId, chartData) {
        if (!insight) return;
        if (!measurementSummaryList) return;
        if (!chartData) return;

        chartData.labels = (measurementSummaryList ?? []).map((t) => new Date(t.Timestamp).toLocaleString());
        let i = 0;
        for (const route of insight.Routes){
            const values: Point[] = [];
            for (const measurement of measurementSummaryList) {
                const sectionIds = measurement.SectionMeasurements.SectionIds.split('\t');
                const durations = measurement.SectionMeasurements.Durations.split('\t').map(x => parseFloat(x));
                const typicalDurations = measurement.SectionMeasurements.TypicalDurations.split('\t').map(x => parseFloat(x));
    
                const options: IDeriveOptions = {
                    distance: route.Sections.reduce((acc, cur) => acc + insight.Sections.find((x) => x.Id === cur)?.Distance ?? 0, 0),
                    actualDuration: route.Sections.reduce((acc, cur) => acc + durations[sectionIds.indexOf(cur)] ?? 0, 0),
                    freeFlowDuration: route.Sections.reduce((acc, cur) => acc + insight.Sections.find((x) => x.Id === cur)?.FreeFlowDuration ?? 0, 0),
                    typicalDuration: route.Sections.reduce((acc, cur) => acc + typicalDurations[sectionIds.indexOf(cur)] ?? 0, 0),
                }
                values.push({x: values.length - 1, y: indicators.find(i => i.id == indicatorId).derive(options).value});
            }
            const colour = routeInfo[route.Id].color;
            if (chartData.datasets[i]) {
                chartData.datasets[i].data = values;
            } else {
                chartData.datasets[i] = {
                    label: route.Name,
                    data: values,
                    parsing: false,
                    borderColor: `rgb(${colour[0]}, ${colour[1]}, ${colour[2]})`,
                    fill: false,
                    tension: false
                };
            }
            i++;
        }
        const len = measurementSummaryList?.length ?? 0;
        chart.update();

        if (chart && chart.scales && chart.scales.x && (len > 20)) {
            // Asume 20 rightmost samples are in view, and use diff in pixels between 20th and 21st rightmost sample as a
            // measure for the panning.
            // Does not work. const diff = chart.scales.x.getPixelForValue(chart.data.datasets[0].data[len-21].x) - chart.scales.x.getPixelForValue(chart.data.datasets[0].data[len-20].x);
            const diff = -150;
            chart.pan({ x: diff }, undefined, 'default');
        }
        chart.update();
    }

    function handleZoomChange(){
        if(!chart) return;
        const zoomLevel = getCurrentZoomLevel(chart);
        if(zoomLevel === Infinity){
            return;
        }
        logZoomLevel = Math.log(zoomLevel);
    }

    function onZoomChange(newValue: number) {
        if(!chart) return;
        logZoomLevel = Math.max(newValue, 0);
        const newZoomLevel = Math.exp(newValue);
        const currentZoomLevel = getCurrentZoomLevel(chart);
        if(Math.abs(newZoomLevel / currentZoomLevel - 1) <= Number.EPSILON) return;
        updateZoomBounds(newZoomLevel);
    }

    function updateZoomBounds(newZoomLevel: number) {
        if(!chart) return;
        const newRange = getZoomScaleBounds(chart, newZoomLevel);
        chart.zoomScale('x', newRange);
    }

    function getCurrentZoomLevel(chart): number {
        if(!chart) return;
        const xScale = chart.scales.x;
        const initialX = chart.getInitialScaleBounds()['x']
        return (initialX.max - initialX.min) / (xScale.max - xScale.min);
    }

    function getZoomScaleBounds(chart, zoomLevel): {min: number, max: number} {
        if(!chart) return;
        const xScale = chart.scales.x;
        const initialX = chart.getInitialScaleBounds()['x'];
        const newRange = Math.max((initialX.max - initialX.min) / zoomLevel, 1);
        return { min: xScale.max - newRange, max: xScale.max };
    }

    function fetchMeasurements(timestamps: string[], visible: boolean) {
        if (visible) {
            measurementSummaryProvider(timestamps);
        }
    }

    interface ChartData {
        labels: string[];
        datasets: ChartDataset[];
    }

    interface ChartDataset {
        label: string;
        data: Point[];
        parsing: object | false;
        borderColor: string;
        fill: boolean;
        tension: number|boolean;
    }
</script>
