<div bind:this="{mapContainer}" use:mapMounted class="map"/>

<script lang="ts">
    import * as L from 'leaflet';
    import 'leaflet/dist/leaflet.css';
    import { decode } from '@mapbox/polyline';
    import { tick } from 'svelte';
    import type { IFlowGate, IFlowMeasurements, IGate, IInsight, IMeasurement, ISection, ITrafficSegmentMeasurements } from '../utils/apitypes';
    import type { IRouteInfo } from '../utils/types';
    import { COLOR_STRING_GREEN, COLOR_STRING_ORANGE, COLOR_STRING_RED, COLOR_STRING_YELLOW, colorComponentsToRgbString, formatDuration, getLegendColorString } from '../utils/style';
    import { encode } from 'html-entities';
    import { giveOffset } from '../utils/offset';
    import { type IIndicatorValue, indicators, type IDeriveOptions } from '../utils/indicators';
    import { CongestionTrafficFlowGenerator, CoverageTrafficFlowGenerator, SpeedTrafficFlowGenerator, type ITrafficFlowGenerator, type FlowMeasurement, type TrafficMeasurement } from '../utils/trafficFlowGenerators';
    import { notify } from '../utils/notify';
    import { extractRouteCore } from '../utils/routecore';
            
    const PROFILES = {
        here: {
        url: 'https://{s}.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png?apiKey=FILL_IN',
        subdomains: '1234',
        tileSize: 512,
        zoomOffset: -1
        },
        carto: {
        url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
        subdomains: 'abcd',
        tileSize: 512,
        zoomOffset: -1,
        attribution: `&copy;<a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>,
            &copy;<a href="https://carto.com/attributions" target="_blank">CARTO</a>`
        }
    }
    const ROUTE_DISTANCE = 8;  // Meters between colored lines for routes on the same traject
    const SPEED_DASH = '5';
    const MAPBOX_CONGESTION_LEGEND = {
        'Low': [0, 39],
        'Moderate': [34, 59],
        'Heavy': [60, 79],
        'Severe': [80, 100]
    };

    const MAPBOX_LABEL_TRANSLATIONS = {
        'Low': 'Licht',
        'Moderate': 'Matig',
        'Heavy': 'Zwaar',
        'Severe': 'Ernstig'
    }

    let mapContainer: HTMLElement;
    let map: L.Map;
    let repaintScheduled = false;
    let mapRouteElements: L.Layer[] = [];
    let hairlines: L.LayerGroup<any>;
    let trafficMeasurements = new Map<string, TrafficMeasurement>();
    let flowMeasuremements = new Map<string, FlowMeasurement>();

    let firstTimePaint = true;

    export let visible: boolean;
    export let insight: IInsight;
    export let measurement: IMeasurement;
    export let trafficFlowMode: string;
    export let congestionProfile: string[];
    export let routeInfo: {[id: string]: IRouteInfo};
    export let indicatorIdx: number;
    export let showSpeedValues: boolean;

    export let showNdwSpeed: boolean;
    export let showNdwFlow: boolean;
    export let modeNdwActive: boolean;
    export let modeNdwInactive: boolean;

    // Important: include every properties that must trigger a repaint in the "$" expression! Otherwise the repaint is not
    // invoked when the property changes.
    $: repaint(insight, measurement, congestionProfile, trafficFlowMode, indicatorIdx, showSpeedValues, showNdwSpeed, showNdwFlow, modeNdwActive, modeNdwInactive, routeInfo);

    $: if (visible) {
        resizeMap();
    }

    $: parseFlowMeasurements(measurement?.FlowMeasurements);
    $: parseTrafficMeasurements(measurement?.TrafficSegmentMeasurements);

    function mapMounted(container: HTMLElement) {
        map = createMap(container);
        map.on('zoomend', (event: L.LeafletEvent) => {
            repaint(insight, measurement, congestionProfile, trafficFlowMode, indicatorIdx, showSpeedValues, showNdwSpeed, showNdwFlow, modeNdwActive, modeNdwInactive, routeInfo);
        });
    }

    function resizeMap() {
        if(map) { map.invalidateSize(); }
    }

    function createMap(container: HTMLElement) {
        const profile = PROFILES.carto;

        let m = L.map(container, {
            zoomSnap: 0.25,      // Allow more fluent zooming: not only from one tile level to the next (factor 2), but also in between
            zoomDelta: 0.25,
            preferCanvas: true,  // Drastically speeds up rendering by using canvas instead of separate DOM elements per layer,
            zoomControl: false   // We add our own zoom control in the bottom left corner
        });
        L.control.zoom({position: 'bottomleft'}).addTo(m);
        L.tileLayer(
        profile.url,
        {
            attribution: profile.attribution ?? '',
            subdomains: profile.subdomains,
            maxZoom: 17,
            tileSize: profile.tileSize,
            zoomOffset: profile.zoomOffset,
        }
        ).addTo(m);
        clearMap(map);

        return m;
    }

    // Unused parameters are needed to trigger repaint when they change (other methods refer to the global parameters).
    async function repaint(insight: IInsight, measurement: IMeasurement, congestionProfile: string[], trafficFlowMode: string, indicatorIdx: number, 
        showSpeedValues: boolean, showNdwFlow: boolean, showNdwSpeed: boolean, modeNdwActive: boolean, modeNdwInactive: boolean, routeInfo: {[id: string]: IRouteInfo}) {
        if (repaintScheduled) {
            return;
        }
        repaintScheduled = true;
        await tick();
        repaintScheduled = false;
        addRoutes(insight, measurement, trafficFlowMode, indicatorIdx);
    }

    function addHairline(waypoints: [number, number][]) {
        const hairline = L.polyline(waypoints, { color: '#bbb', weight: 2, opacity: 1, lineCap: 'butt'});
        hairline.addTo(hairlines);
    }

    function addPolyline(waypoints: [number, number][], offset: number, color: string, weight: number, opacity: number, tooltip: string) {
        const offsetted = offset == 0 ? waypoints : giveOffset(waypoints, offset * ROUTE_DISTANCE, map.getZoom());
        const polyline = L.polyline(offsetted, { color: color, weight: weight, opacity: opacity, dashArray: SPEED_DASH});
        polyline.bindTooltip(tooltip, { sticky: true });
        mapRouteElements.push(polyline);
        polyline.addTo(map);
    }

    function centerMap(gates: IGate[]) {
        // It is nicer to use bounding box of all gate coordinates. But we simply use the first gate only
        // which is a nice approximation which may go unnoticed by the user.
        if (gates.length > 0) {
            map.setView([gates[0].Coord.Lat, gates[0].Coord.Lng], 12);
        }
    }

    function addRoutes(insight: IInsight, measurement: IMeasurement, trafficFlowMode: string, indicatorIdx: number) {
        if (!insight) return;
        if (!map) return;

        clearMap(map);

        if (firstTimePaint && insight && (insight.Gates?.length ?? 0 > 0)) {
            firstTimePaint = false;
            centerMap(insight.Gates);
        }

        // Traffic flow mode
        // TODO: Pass this object in from InsightFrame
        let flowGenerator : ITrafficFlowGenerator;
        switch(trafficFlowMode) {
            case 'coverage': flowGenerator = new CoverageTrafficFlowGenerator(); break;
            case 'speedhq': flowGenerator = new SpeedTrafficFlowGenerator(0.75); break;
            case 'speedlq': flowGenerator = new SpeedTrafficFlowGenerator(0.71); break;
            case 'congestionhq': flowGenerator = new CongestionTrafficFlowGenerator(0.75); break;
            case 'congestionlq': flowGenerator = new CongestionTrafficFlowGenerator(0.71); break;
        }

        if (flowGenerator) {
            for (const segment of insight.TrafficSegments) {
                const waypoints = decode(segment.Polyline, 8);
                const segmentMeasurement = trafficMeasurements.get(segment.Id);
                const result = flowGenerator.generate(segment, segmentMeasurement);
                if (!result) continue;
                if (result.offset) {
                    addHairline(waypoints);
                    addPolyline(waypoints, (2 + insight.Routes.length), result.color, result.weight, result.opacity, result.tooltip);
                } else {
                    addPolyline(waypoints, 0, result.color, result.weight, result.opacity, result.tooltip);
                }
            }
        }

        // Congestion mode
        if (congestionProfile.length > 0 && measurement.CongestionMeasurement) {
            const colors = {
                Low: COLOR_STRING_GREEN,
                Moderate: COLOR_STRING_YELLOW,
                Heavy: COLOR_STRING_ORANGE,
                Severe: COLOR_STRING_RED
            }
            for (const severity of congestionProfile.reverse()) {
                const segments = measurement.CongestionMeasurement[severity];
                if (!segments) {
                    continue;
                }
                const color = colors[severity] ?? '#888888';
                for (const segment of segments) {
                    const waypoints = decode(segment, 8);
                    const offsetted = giveOffset(waypoints, (1 + insight.Routes.length) * ROUTE_DISTANCE, map.getZoom());
                    const pl = L.polyline(offsetted, { color, weight: 3, opacity: 1, dashArray: SPEED_DASH});
                    const delay = (severity && MAPBOX_CONGESTION_LEGEND[severity]) ? `${MAPBOX_CONGESTION_LEGEND[severity][0]}-${MAPBOX_CONGESTION_LEGEND[severity][1]}%` : '-';
                    pl.bindTooltip(`Mapbox: ${encode(MAPBOX_LABEL_TRANSLATIONS[severity])} (${delay}) `, { sticky: true });
                    mapRouteElements.push(pl);
                    pl.addTo(map);
                    addHairline(waypoints);
                }
            }
        }

        const sectionIds = measurement?.SectionMeasurements?.SectionIds.split('\t') ?? [];
        const durations = measurement?.SectionMeasurements?.Durations.split('\t').map(x => parseFloat(x)) ?? [];
        const typicalDurations = measurement?.SectionMeasurements?.TypicalDurations.split('\t').map(x => parseFloat(x)) ?? [];

        insight.Sections.forEach((section, i) => {
            const values: IIndicatorValue[] = [];
            const idx = sectionIds.indexOf(section.Id);
            const options: IDeriveOptions = {
                distance: section.Distance,
                actualDuration: durations[idx],
                freeFlowDuration: section.FreeFlowDuration,
                typicalDuration: typicalDurations[idx]
            }
            for (const indicator of indicators) {
                values.push(indicator.derive(options));
            }
            renderSection(section, values, indicatorIdx);
        });

        for (const gate of insight.Gates) {
            renderGate(gate, map);
        }

        if (modeNdwActive || modeNdwInactive) {  
            // Change for every item, so that the chance of having tooltips behind each other reduces.
            let direction = 0;
            const DIRECTIONS: L.Direction[] = ['bottom', 'left', 'right', 'top'];
            
            for (const fg of insight.FlowGates) {
                const dir = DIRECTIONS[direction % DIRECTIONS.length];
                renderFlowGate(fg, map, dir);
                direction++;
            }
        }
    }

    function renderSection(section: ISection, values: IIndicatorValue[], indicatorIdx: number) {
        const allRoutesForSection = insight.Routes.filter((x) => x.Sections.includes(section.Id));
        const routesForSection = allRoutesForSection.filter((route) => routeInfo[route.Id]?.hidden !== true);
        if (routesForSection.length === 0) {
            return;
        }
        
        const haveIndicator = indicatorIdx >= 0;
        const currentIndicator = indicators[indicatorIdx];
        const value = values[indicatorIdx];
        
        const textValue = (currentIndicator?.type === 'duration') ? extractDurationAmount(formatDuration(value?.value)) : value?.value?.toFixed(0) ?? '-';
        const unitValue = (currentIndicator?.type === 'duration') ? extractDurationUnit(formatDuration(value?.value)) : currentIndicator?.unit ?? '';
        const color = value?.value === undefined ? [127, 127, 127, 1] : value.color;
        const rgba = `rgba(${color[0]},${color[1]},${color[2]},${color[3]})`;

        if (!section.Polyline) {
            // We do not have polyline data. Just draw a straight dashed line between the gates.
            const gfrom = insight.Gates.find((x) => x.Id === section.GateFrom);
            const gto = insight.Gates.find((x) => x.Id === section.GateTo);
            if (gfrom && gto) {
                const waypoints: L.LatLngExpression[] = [[gfrom.Coord.Lat, gfrom.Coord.Lng], [gto.Coord.Lat, gto.Coord.Lng]];
                const polyline = L.polyline(waypoints, { color: rgba, dashArray: [4] });
                if (haveIndicator) {
                    polyline.bindTooltip(`${encode(textValue)} ${encode(unitValue)}`, {permanent: showSpeedValues, direction: 'center'});
                }
                mapRouteElements.push(polyline);
                polyline.addTo(map);
            }
            return;
        }
        
        // We have polyline data available which nicely following the roads.
        // Draw it using color coding.
        const waypoints = decode(section.Polyline, 8);
        if (haveIndicator) {
            const indicatorPolyline = L.polyline(waypoints, { color: rgba, weight: 5, opacity: 1 });
            // Gray border around the real colored line
            const border = L.polyline(waypoints, { color: '#777', weight: 7, opacity: 0.5});
            border.addTo(hairlines);
            mapRouteElements.push(indicatorPolyline);
            indicatorPolyline.addTo(map);
        } else {
            addHairline(waypoints);
        }

        routesForSection.forEach((route, j) => {
            if (!(routeInfo[route.Id]?.hidden !== true)) {
                return;
            } 

            const routeColor = routeInfo[route.Id].color;
            const routergba = `rgba(${routeColor[0]},${routeColor[1]},${routeColor[2]}, ${haveIndicator ? 0.2 : 1.0})`;
            let caption = '';
            caption += `<div><span style="display: inline-block; width: 12em;">Route:</span>` +
                    `<span style="display: inline-block; width: 2rem; text-align: right;">${encode(route.Name)}</span></div>`;
            indicators.forEach((ind, idx) => {
                const textValue = (ind.type === 'duration') ? extractDurationAmount(formatDuration(values[idx].value)) : (values[idx].value?.toFixed(0) ?? '-');
                const unit = (ind.type === 'duration') ? extractDurationUnit(formatDuration(values[idx].value)) : ind.unit;
                const bold = (idx === indicatorIdx) ? 'font-weight: bold;' : '';
                caption += `<div><span style="display: inline-block; width: 12em; ${bold}">${encode(ind.displayName)}:</span>` +
                    `<span style="display: inline-block; width: 2rem; text-align: right;">${encode(textValue)}</span> ${encode(unit)}</div>`;
            });
            
            const routeIndex = insight.Routes.indexOf(route);
            const offset = giveOffset(waypoints, ROUTE_DISTANCE*(1 + routeIndex), map.getZoom());
            const routeLine = L.polyline(offset, { color: routergba, weight: 3, opacity: 1, lineCap: 'butt'});

            if (showSpeedValues) {
                if (haveIndicator) {
                    routeLine.bindTooltip(`${encode(textValue)} ${encode(unitValue)}`, {permanent: true, direction: 'center'});
                    routeLine.bindPopup(caption);
                } else {
                    routeLine.bindTooltip(caption, {sticky: true, direction: 'center'});
                }
            } else {
                routeLine.bindTooltip(caption, {sticky: true, direction: 'center'});
            }
            mapRouteElements.push(routeLine);
            routeLine.addTo(map);
        });
    }

    function renderGate(gate: IGate, map: L.Map){
        const endRoutes: string[] = [];
        const routes = insight.Routes.filter( (x) => x.Gates.includes(gate.Id) && (routeInfo[x.Id]?.hidden !== true));
        routes.forEach((route, i) => {
            const radius = (routes.length - i) * 50;
            const color = getLegendColorString(routeInfo[route.Id].color);
            const startGate = route.Gates[0] === gate.Id;
            const endGate = route.Gates[route.Gates.length - 1] === gate.Id;
            const marker = L.circle([gate.Coord.Lat, gate.Coord.Lng], {color, radius, opacity: (startGate || endGate) ? 1.0 : 0.1});
            const gatekind = startGate ? 'Startgate' : endGate ? 'Eindgate' : 'Gate';
            marker.bindTooltip(`${encode(gatekind)} Route ${encode(route.Name)} Gate ${encode(gate.Name)}`);
            if (startGate || endGate) {
                const core = extractRouteCore(route.Name);
                if (!endRoutes.includes(core)) {
                    endRoutes.push(core);
                }
            }
            marker.addTo(map);
            mapRouteElements.push(marker);
        });
        if (endRoutes.length > 0) {
            const tooltip = L.tooltip( {permanent: true, direction: 'top', opacity: 0.7});
            tooltip.setContent(endRoutes.join('<br>'));
            tooltip.setLatLng([gate.Coord.Lat, gate.Coord.Lng]);
            tooltip.addTo(map);
            mapRouteElements.push(tooltip);
        }
    }

    function renderFlowGate(flowGate: IFlowGate, map: L.Map, dir: L.Direction) {
        const m = flowMeasuremements?.get(flowGate.Id);
        if (modeNdwActive && (!m) ) return;

        const flow = m?.flow ? m.flow / 60 : undefined;
        const marker = L.circle([flowGate.Coord.Lat, flowGate.Coord.Lng], {radius: 5, color: !!m ? '#0000ff' : '#999999'});
        marker.bindTooltip(`${encode(flowGate.Id)} - ${encode(flowGate.Name)} - ${encode(flowGate.Direction)} - ${encode(m?.speed?.toFixed(0) ?? '-')} km/u - ${encode(flow?.toFixed(0) ?? '-')} veh/min`);
        marker.addTo(map);
        marker.on('click', async () => {
            await navigator.clipboard.writeText(flowGate.Id);
            notify(`De id [${flowGate.Id}] is gekopieerd naar het klembord`);
        })
        mapRouteElements.push(marker);
        // Use opacity 0.5 here to let user see that there may be another tooltip behind. This happens a lot for
        // 2 measurement points on opposite side of the same street. TODO: Make this nicer.
        let label = '';
        if(showNdwSpeed) {
            label = `${encode(m?.speed?.toFixed(0) ?? '-')} km/u`;
        }
        if(showNdwFlow) {
            if(label.length > 0) { 
                label += ' - ';
            }
            label += `${encode(flow?.toFixed(0) ?? '-')} veh/min`;
        }
        if(label) {
            marker.bindTooltip(label, {permanent: true, opacity: 0.5, direction: dir});
        }
    }

    function clearMap(map: L.Map) {
        if(!map) return; 

        for (const item of mapRouteElements) {
            item.removeFrom(map);
        }
        mapRouteElements = [];
        hairlines = L.layerGroup();
        mapRouteElements.push(hairlines);
        hairlines.addTo(map);
    }

    function parseTrafficMeasurements(m?: ITrafficSegmentMeasurements) {
        trafficMeasurements.clear();
        if (!m) {
            trafficMeasurements = trafficMeasurements;
            return;
        }

        const ids = m.SegmentIds.split('\t') ?? [];
        const speeds = m.Speeds.split('\t') ?? [];
        const jamFactors = m.JamFactors.split('\t') ?? [];
        const confidences = m.Confidences.split('\t') ?? [];
        
        for (let idx=0; idx<ids.length; idx++) {
            trafficMeasurements.set(ids[idx], { 
                jamFactor: jamFactors[idx] ? parseFloat(jamFactors[idx]) : undefined,
                speed: speeds[idx] ? parseFloat(speeds[idx]) : undefined,
                confidence: confidences[idx] ? parseFloat(confidences[idx]) : undefined,
            });
            trafficMeasurements = trafficMeasurements;
        }
    }

    function parseFlowMeasurements(m?: IFlowMeasurements) {
        flowMeasuremements.clear();
        if (!m) {
            flowMeasuremements = flowMeasuremements;
            return;
        }

        const flowGates = m.FlowGateIds.split('\t') ?? [];
        const flowFlows = m.Flows.split('\t') ?? [];
        const flowSpeeds = m.Speeds.split('\t') ?? [];
        
        for (let idx=0; idx<flowGates.length; idx++) {
            flowMeasuremements.set(flowGates[idx], { 
                flow: flowFlows[idx] ? parseFloat(flowFlows[idx]) : undefined,
                speed: flowSpeeds[idx] ? parseFloat(flowSpeeds[idx]) : undefined
            });
            flowMeasuremements = flowMeasuremements;
        }
    }
    
    export function extractDurationAmount(value: string) {
        if (value === '-') return '-';
        const nr = parseInt(value);
        return nr.toString();
    }

    export function extractDurationUnit(value: string) {
        if (value === '-') return 's';
        const amount = extractDurationAmount(value);
        return value.slice(amount.length);
    }
</script>
<style lang="scss">
    .map { width: 100%; height: 100%; position: relative; }
</style>