﻿<FloatingButton 
        label="Here traffic" 
        active={selectedTrafficFlowMode !== 'none'}
        activeIcon="check"
        inactiveIcon=""
        popoverWidth="348px">
    
    <List ul={false}>
        <ListGroup>
            <ListItem title="Verkeersbeeld" groupTitle />
            
            {#each HERE_TRAFFIC_OPTIONS as option}
                <ListItem 
                        radio 
                        radioIcon="end" 
                        value={option.value} 
                        title={option.label} 
                        checked={selectedTrafficFlowMode === option.value} 
                        on:change={() => selectTrafficFlowMode(option.value)}/>
            {/each}
            
        </ListGroup>
    </List>
    
</FloatingButton>

<script lang="ts">
    import {List, ListGroup, ListItem} from "framework7-svelte";
    import FloatingButton from "../FloatingButton.svelte";
    import {createEventDispatcher} from "svelte";

    const HERE_TRAFFIC_OPTIONS: {label: string, value: string}[] = [
        {label: 'Niet weergeven', value: 'none'},
        {label: 'Dekking verkeersbeeld', value: 'coverage'},
        {label: 'Snelheid hoge betrouwbaarheid', value: 'speedhq'},
        {label: 'Snelheid lage betrouwbaarheid', value: 'speedlq'},
        {label: 'Hinder hoge betrouwbaarheid', value: 'congestionhq'},
        {label: 'Hinder lage betrouwbaarheid', value: 'congestionlq'}
    ];
    
    let selectedTrafficFlowMode = 'none';

    const eventDispatcher = createEventDispatcher();

    function selectTrafficFlowMode(trafficFlowMode: string) {
        selectedTrafficFlowMode = trafficFlowMode;
        eventDispatcher('trafficFlowModeSelected', trafficFlowMode);
    }
    
</script>