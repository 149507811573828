import type { IInsight } from "./apitypes";
import type { IGate, IGateDefinition, IInsightDefinition, IRouteDefinition } from "./model";

export function parseCsv(csv: string, projectId: string): IInsightDefinition {
    const data: IInsightDefinition = {
        ProjectId: projectId,
        Routes: [],
        Gates: []
    }

    const lines = csv.split('\n').slice(1);  // Ignore 1st header line
    for (const line of lines) {
        if (line.trim() === '') {
            continue;
        }
        if (!line.includes(':')) {
            const gate = parseGate(line);

            if (data.Gates.find((x) => x.Id === gate.Id)) {
                throw new Error(`Gate ${gate.Id} already exists`);
            }

            data.Gates.push(gate);
        } else {
            const route = parseRoute(line, data.Gates);

            if (data.Routes.find((x) => x.Id === route.Id)) {
                throw new Error(`Route ${route.Id} already exists`);
            }

            data.Routes.push(route);
        }
    }

    return data;
}

function parseGate(line: string): IGateDefinition {
    const fields = line.split(',');
    if (fields.length <3) {
        throw new Error(`Invalid line: "${line}"". Line must contain 3 fields. The import is aborted.`);
    }
    const gateId = fields[2];

    // Note: Fields in CSV are order x,y which is lon,lat instead of lat,lon.
    const lat = parseFloat(fields[1]);
    if (isNaN(lat)) {
        throw new Error(`Invalid line: "${line}". Latitude value "${fields[0]}" is not a valid floating point number. The import is aborted.`);
    }
    const lon = parseFloat(fields[0]);
    if (isNaN(lon)) {
        throw new Error(`Invalid line: "${line}". Longitude value "${fields[1]}" is not a valid floating point number. The import is aborted.`);
    }
    return { Id: gateId, Name: gateId, Coord: { Lat: lat, Lng: lon }};
}

function parseRoute(line: string, gates: IGateDefinition[]): IRouteDefinition {
    const split = line.split(':');
    const routeId = split[0];
    const gateIds = split[1].split(',');
    
    const route = { Id: routeId, Name: routeId, Gates: []};
    for (const gateId of gateIds){
        const gate = gates.find((x) => x.Id === gateId);
        if(!gate){
            throw new Error(`Gate ${gateId} was not found`);
        }
        route.Gates.push(gate.Id);
    }
    return route;
}

export function parseOldFormat(csv: string, projectId: string) {
    const data: IInsightDefinition = {
        ProjectId: projectId,
        Routes: [],
        Gates: []
    }

    const lines = csv.split('\n').slice(1);  // Ignore 1st header line
    for (const line of lines) {
        if (line.trim() === '') {
            continue;
        }
        const fields = line.split(',');
        if (fields.length < 3) {
            throw new Error(`Invalid line: "${line}"". Line must contain 3 fields. The import is aborted.`);
        }
        const split = fields[2].split('-');
        const gateId = split[1];
        const routeIds = split[0].split('+');

        const gate = { Id: gateId, Name: gateId, Coord: { Lat: fields[1], Lng: fields[0] }};
        data.Gates.push(gate);
        for(const routeId of routeIds) {
            console.log(routeId);
            const route = data.Routes.find((x) => x.Id === routeId);
            if (route) {
                route.Gates.push(gateId);
            } else {
                data.Routes.push({ Id: routeId, Name: routeId, Gates: [gateId] });
            }
        }
    }
    console.log(data);
    return data;
}

export function toNewFormat(insight: IInsightDefinition) : string {
    const csv = [];
    csv.push('X,Y,Name,description');
    for (const gate of insight.Gates) {
        csv.push(`${gate.Coord.Lng},${gate.Coord.Lat},${gate.Id}`);
    }
    csv.push('');	
    for (const route of insight.Routes) {
        csv.push(`${route.Id}:${route.Gates.join(',')}`);
    }
    return csv.join('\n');
}