<Page>
    <Header/>
    
    <Block>
        {#each allpages as page}
        <Link href={page.href} loginScreenOpen={page.login ? '#login-screen' : undefined}>
            <Card class="main-page-square">
                <CardContent>
                    <div class="text-align-center">
                    <Icon f7={page.icon} size="200"></Icon>
                    </div>
                </CardContent>
                <CardFooter>
                    <div class="text-align-center" style="width: 100%">
                    {page.name}
                    </div>
                </CardFooter>
            </Card>
        </Link>
        {/each}
    </Block>
</Page>

<script lang="ts">
    import { Icon, Page, Block, Card, CardContent, Link, CardFooter, f7 } from 'framework7-svelte';
    import { onMount } from 'svelte';
    import { navparts } from '../utils/navparts';
    import { credentials, type ICredentials } from '../utils/credentials';
    import { PERMISSION_VIEW_ACCESS, PERMISSION_VIEW_PROJECTS, PERMISSION_VIEW_RATES } from '../utils/permissions';
    import Header from '../components/Header.svelte';

    onMount(() => {
        navparts.set([]);
    });

    interface IPage {
        name: string;
        href?: string;
        icon: string;
        login?: boolean;
    }

    let allpages: IPage[] = [];

    $: allpages = deriveAllPages($credentials, $PERMISSION_VIEW_PROJECTS, $PERMISSION_VIEW_ACCESS, $PERMISSION_VIEW_RATES);

    function deriveAllPages(creds: ICredentials, permission_VIEW_PROJECTS: boolean, permission_VIEW_ACCESS: boolean, permission_VIEW_RATES: boolean) {
        const pages = [];
        if(creds) {
            if (permission_VIEW_PROJECTS) {
                pages.push({ name: 'Projecten', href:'/projects', icon: 'pin'})
            };
            if (permission_VIEW_ACCESS) {
                pages.push({ name: 'Toegangslog', href:'/access', icon: 'doc_person'})
            };
            if (permission_VIEW_RATES) {
                pages.push({ name: 'Tarieven', href:'/rates', icon: 'money_euro_circle'})
            };
        }
        pages.push({ name: 'In/Uitloggen', icon: 'person_circle', login: true });
        return pages;
    }

</script>

<style lang="scss">
    :global(.main-page-square) {
            width: 350px;
            height: 300px;
            margin: 2rem;
            display: inline-block;
    }
</style>