import { credentials, type ICredentials } from "./credentials";

let c: ICredentials | undefined;

credentials.subscribe((value) => {
    c = value;
});

const LOCAL_STORAGE_DEVICE_ID = 'DEVICE_ID';

function obtainDeviceId(): string {
    let did = localStorage.getItem(LOCAL_STORAGE_DEVICE_ID);
    if (!did) {
        did = (window.crypto) ? window.crypto.randomUUID() : Math.random().toString();
        localStorage.setItem(LOCAL_STORAGE_DEVICE_ID, did);
    }
    return did;
}

export async function makeApiCall(name: string, content?: unknown) {
    if (!c) {
        throw new Error('No credentials');
    }
    const auth = btoa(`${c.username}:${c.password}`);
    const result = await fetch(`/api/${name}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${auth}`,
            'X-Device-Id': obtainDeviceId()
        },
        body: content ? JSON.stringify(content) : undefined
    });

    if (result.status !== 200) {
        throw new Error(`Error ${result.status} occurred while making an API call to ${name}: ${result.statusText}`);
    }

    if (result.headers.get('content-type') === 'application/json') {
        return result.json();
    }
}