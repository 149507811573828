<Page>
    <Header/>
    
    <ActionBar>
        {#if $PERMISSION_MODIFY_PROJECTS}
            <Button outline class="display-inline-flex padding-horizontal" href="/project/new" iconMaterial="add">Project toevoegen</Button>
        {/if}
    </ActionBar>
    
    <Block>
        {#if items}
        <div class="card data-table">
        <table>
            <thead>
            <tr>
                <th style="width: 30em">
                    <span class="table-head-label">ID</span>
                </th>
                <th class="input-cell">
                    <span class="table-head-label">Name</span>
                    <div class="input">
                        <input type="text" placeholder="Filter" />
                    </div>
                </th>
                <th>
                    <span class="table-head-label">Opname</span>
                </th>
            </tr>
            </thead>
            <tbody>
            {#each items as item, i}
            <tr>
                <td>{item.Id}</td>
                <td><Link href="/project/{item.Id}">{item.Name}</Link></td>
                <td>
                    {#each (Object.keys(item.Recordings ?? {})) as recording}
                        {#if item.Recordings[recording]}  
                        <Chip text="{recording}" mediaBgColor="blue" media={item.Recordings[recording]} class="margin-right"/>
                        {/if}
                    {/each}
                </td>
            </tr>
            {/each}
            </tbody>
        </table>
        </div>
        {/if}        
    </Block>
</Page>

<script lang="ts">
    import { Page, Block, Link, Button, Chip } from 'framework7-svelte';
    import { onMount } from 'svelte';
    import type { IProjectInfo } from '../utils/apitypes';
    import { navparts } from '../utils/navparts';
    import ActionBar from '../components/ActionBar.svelte';
    import { makeApiCall } from '../utils/api';
    import Header from '../components/Header.svelte';
    import { PERMISSION_VIEW_PROJECTS, PERMISSION_MODIFY_PROJECTS } from '../utils/permissions';

    interface IProjectInfoList {
        Items: IProjectInfo[];
    }

    let items: IProjectInfo[] = [];

    onMount(async () => {
        items = [];

        navparts.set([
            { name: 'Projecten', href: '/projects'},
        ]);
    });

    PERMISSION_VIEW_PROJECTS.subscribe(async (value) => {
        if (!value) {
            return;
        }
        const infoData = await makeApiCall('projects/get') as IProjectInfoList;
        for (const item of infoData.Items) {
            items.push(item);
        }
        items = items;
    });

</script>