// From: https://www.schemecolor.com/red-orange-yellow-green.php

export const COLOR_STRING_GREEN = '#006B3E';   // Cadmium Green
export const COLOR_STRING_YELLOW = '#FFE733';  // Banana yellow
export const COLOR_STRING_RED = '#ED2938';     // Imperial red
export const COLOR_STRING_ORANGE = '#FF8C01';     // Imperial red
export const COLOR_STRING_BLUE = '#2460BD';    // Denim Blue

export const COLORS_RGB_LEGEND: [number, number, number][] = [
    [1,0,0], [0,1,0], [0,0,1], [1,1,0], [1,0,1], [0,1,1], 
    [1,0.5,0], [0.5,1,0], [0.5,0,1],
    [1,0,0.5], [0,1,0.5], [0,0.5,1], 
    [1,1,0.5], [1,0.5,1], [0.5,1,1]
];
   
export function getLegendColorString(c: [number,number,number], opacity?: number) {
    if (opacity !== undefined) {
        return `rgba(${c[0]},${c[1]},${c[2]},${opacity})`;
    } else {
        return `rgb(${c[0]},${c[1]},${c[2]})`;
    }
}

export function getLegendColorRGB(index: number): [number,number,number] {
    const c = (index >= 0) ? COLORS_RGB_LEGEND[index % COLORS_RGB_LEGEND.length] : [0.5,0.5,0.5];
    return [c[0]*255,c[1]*255,c[2]*255];
}

export function getLegendColorRGBA(index: number, opacity: number): [number,number,number, number] {
    return [...getLegendColorRGB(index), opacity];
}

export function colorComponentsToRgbString(c: [number, number, number]): string {
    return `rgb(${c[0]},${c[1]},${c[2]})`;
}

export function formatDuration(seconds?: number) {
    if (seconds === undefined) {
        return '-';
    }
    const positive = (seconds >= 0);
    const sign = positive ? '' : '-';
    seconds = positive ? seconds : -seconds;
    return (seconds < 60) ? `${sign}${Math.floor(seconds)}s`
    : (seconds < 60*60) ? `${sign}${Math.floor(seconds/60)}m ${Math.floor(seconds % 60)}s`
    : `${sign}${Math.floor(seconds/(60*60))}u ${Math.floor(seconds % (60*60))}m`
}

export function formatMoment(dateTime: Date) {
    return dateTime.toLocaleTimeString(undefined, {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit'
    });
}

export function formatMomentTime(dateTime: Date) {
    return dateTime.toLocaleTimeString(undefined, {hour: 'numeric', minute: '2-digit', second: '2-digit' });
}
