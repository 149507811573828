<Page pageContent={false} id={id}>
    <Popover class="menu">
        <List ul={false} >
            <ListGroup>
                <ListItem href="/" popoverClose>Menu</ListItem>
            </ListGroup>
            {#if $PERMISSION_CONFIG_INSIGHT}
              <ListGroup>
                <ListItem title="Importeer" groupTitle></ListItem>
                <ListItem link="#" title="Importeer routes" popupOpen="#import-popup" popoverClose>
                  <Icon slot="media" material="upload"/>
                </ListItem>
                <ListItem link="#" title="Importeer route metingen" popupOpen="#import-measurements-popup" popoverClose>
                  <Icon slot="media" material="upload"/>
                </ListItem>
              </ListGroup>
            {/if}
            {#if $PERMISSION_VIEW_INSIGHT}
              <ListGroup>
                  <ListItem title="Inzage informatie" groupTitle></ListItem>
                  <ListItem link="#" title="Sectie tabel" popupOpen="#table-section" popoverClose>
                    <Icon slot="media" material="table_chart"/>
                  </ListItem>
                  <ListItem link="#" title="Route tabel" popupOpen="#table-route" popoverClose>
                    <Icon slot="media" material="table_chart"/>
                  </ListItem>
                  <ListItem link="#" title="Route grafiek" popupOpen="#graph-route" popoverClose>
                    <Icon slot="media" material="show_chart"/>
                  </ListItem>
                  <ListItem link="#" title="Export flow gegevens" popupOpen="#popup-export-flow" popoverClose>
                    <Icon slot="media" material="save_as"/>
                  </ListItem>
              </ListGroup>
            {/if}
            <ListGroup>
              <ListItem title="Besturing" groupTitle></ListItem>
              {#if $PERMISSION_CONFIG_INSIGHT}
                  <ListItem link="#" title="Route beheer" popupOpen="#route-management-section" popoverClose>
                      <Icon slot="media" material="table_chart"/>
                  </ListItem>
              {/if}
              {#if $PERMISSION_USE_INSIGHT}
                <ListItem link="#" title="Meting uitvoeren" on:click={doMeasurement} popoverClose>
                <Icon slot="media" material="commit"/>
              </ListItem>
              {/if}
              <ListItem link="#" title="Verversen" on:click={() => refreshAll()} popoverClose>
                <Icon slot="media" material="refresh"/>
              </ListItem>
              {#if $PERMISSION_CONFIG_INSIGHT}
              <ListItem link="#" title="Meetinstellingen" popupOpen="#measurement-interval-popup" popoverClose>
                <Icon slot="media" material="straighten"/>
              </ListItem>
              {/if}
            </ListGroup>
        </List>	
    </Popover>

    <PageContent>
        
        <InsightMap 
                insight={insight} 
                measurement={measurement} 
                trafficFlowMode={trafficFlowMode} 
                indicatorIdx={indicatorIdx}
                congestionProfile={congestionProfile} 
                routeInfo={routeInfo} 
                showSpeedValues={showSpeedValues} 
                visible={visible} 
                showNdwFlow={showNdwFlow} 
                showNdwSpeed={showNdwSpeed} 
                modeNdwActive={modeNdwActive} 
                modeNdwInactive={modeNdwInactive}/>

        <FloatingButtonContainer>
          <TimestampSelector 
                  on:timestampSelected="{selectTimestamp}" 
                  timestamps="{timestamps}" />
            
          <IndicatorSelector 
                  on:indicatorSelected="{selectIndicator}" 
                  on:showValuesChanged="{setShowValues}"/>
            
          <PointMeasurementSelector
                  bind:modeNdwActive={modeNdwActive} 
                  bind:modeNdwInactive={modeNdwInactive} 
                  bind:showNdwSpeed={showNdwSpeed} 
                  bind:showNdwFlow={showNdwFlow} />
            
          <HereTrafficOptionSelector 
                  on:trafficFlowModeSelected={selectTrafficFlowMode} />
          
          <MapboxTrafficOptionSelector
                  bind:congestionProfile={congestionProfile} />
            
          <RouteSelector 
                  bind:insight={insight} 
                  bind:routeInfo={routeInfo}
                  on:selectionChanged="{repaint}" />

          <svelte:fragment slot="right">
            <div>
              <Link class="btn btn-graph" popupOpen="#graph-route" iconOnly iconMaterial="bid_landscape"/>
            </div>
            <div>
              <Link class="btn btn-menu" popoverOpen=".menu" iconOnly iconMaterial="menu"/>
            </div>
          </svelte:fragment>
        </FloatingButtonContainer>
    </PageContent>
</Page>

<Popup id="import-popup" opened={importPopupOpened} onPopupOpened={(popup) => importPopupClose = () => popup.close()} onPopupClosed={() => (importPopupOpened = false)}>
  <Page>
    <Navbar title="CSV Import">
      <NavRight>
        <Link popupClose>Sluiten</Link>
      </NavRight>
    </Navbar>
    <Block>
      <List noHairlinesMd>
        <ListInput label="CSV" type="textarea" placeholder="CSV" resizable value={importCsvContents} onInput={updateCsvContents}>
        </ListInput>
    
        <ListItem>
            <Button disabled={!canImport(importCsvContents)} fill on:click={doImport}>Importeren</Button>
            <Button disabled={canImport(importCsvContents)} fill on:click={reformatImport}>Omzetten</Button>
        </ListItem>
      </List>        
    </Block>
  </Page>
</Popup>

<Popup id="import-measurements-popup" opened={importMeasurementsPopupOpened} onPopupOpened={(popup) => importMeasurementsPopupClose = () => popup.close()} onPopupClosed={() => (importMeasurementsPopupOpened = false)}>
  <Page>
    <Navbar title="CSV Measurements Import">
      <NavRight>
        <Link popupClose>Sluiten</Link>
      </NavRight>
    </Navbar>
    <Block>
      <List>
        <ListInput label="CSV" type="textarea" placeholder="CSV" resizable value={importMeasurementsCsvContents} onInput={updateMeasurementsCsvContents}>
        </ListInput>
    
        <ListItem>
            <Button disabled={!canImport(importMeasurementsCsvContents)} fill on:click={doImportMeasurements}>Importeren</Button>
        </ListItem>
      </List>        
    </Block>
  </Page>
</Popup>

{#if $PERMISSION_VIEW_INSIGHT && insight}
    <Popup 
            id="route-management-section" 
            bind:opened={routeManagementSectionOpened} 
            onPopupClosed={() => (routeManagementSectionOpened = false)} 
            tabletFullscreen={true}>
        {#if routeManagementSectionOpened}
        <RouteManager 
                visible="{routeManagementSectionOpened}" 
                insight="{insight}"
                projectName="{projectName}"
                on:insightUpdated={refreshInsightStaticData}/>
        {/if}
    </Popup>
    
<Popup id="table-section" bind:opened={tableSectionOpened} onPopupClosed={() => (tableSectionOpened = false)} tabletFullscreen={true}>
  <InsightTable title="Sectie" headers={["From", "To"]} allTimestamps={timestamps} insight={insight} allKnownMeasurements={measurementSummaryList} 
    measurementSummaryProvider={updateMeasurementSummaries} indicatorDataFunc={prepareSectionTableData} csvName='sections.csv'/>
</Popup>

<Popup id="table-route" bind:opened={tableRouteOpened} onPopupClosed={() => (tableRouteOpened = false)} tabletFullscreen={true}>
  <InsightTable title="Route" headers={["Name"]} allTimestamps={timestamps} insight={insight} allKnownMeasurements={measurementSummaryList} 
    measurementSummaryProvider={updateMeasurementSummaries} indicatorDataFunc={prepareRouteTableData} csvName='routes.csv'/>
</Popup>

<Popup id="graph-route" bind:opened={graphRouteOpened} tabletFullscreen={true}>
  <InsightGraph visible={graphRouteOpened} allTimestamps={timestamps} measurementSummaryList={measurementSummaryList} measurementSummaryProvider={updateMeasurementSummaries} insight={insight} routeInfo={routeInfo}/>
</Popup>

<Popup id="popup-export-flow">
  {#if exportFlowInProgress}
  <Progressbar id="exportFlowProgressBar"></Progressbar>
  {/if}
  <List>
    <ListInput label="Meetpunten" type="textarea" placeholder="Meetpunten (komma-gescheiden)" resizable bind:value={exportFlowFlowGateIds}></ListInput>
    <ListItem>
      <Button on:click={exportFlow}>Downloaden</Button>
    </ListItem>
  </List>
</Popup>
{/if}

{#if $PERMISSION_CONFIG_INSIGHT}
<Popup id="measurement-interval-popup">
    <MeasurementIntervalPopup intervals={insight?.MeasurementIntervals ?? {}} 
        mapboxZoomLevel={insight?.MapboxZoomLevel} hereFunctionalClass={Math.max(...(insight?.HereFunctionalClasses ?? []))} on:changed={(ev) => changeMeasurementConfig(ev.detail)}>
    </MeasurementIntervalPopup>
</Popup>
{/if}

<script lang="ts">
  import { Block, ListInput, Button, List, ListItem, Link, Popup, Page, Navbar, NavRight, ListGroup, Icon, PageContent, f7, Progressbar, Popover } from 'framework7-svelte';
  import { onDestroy, onMount, tick } from 'svelte';
  import type { IImportedMeasurement, IImportedMeasurements, IInsight, IMeasurement, IMeasurementConfig, IMeasurementIntervals, IMeasurementTimestampsOptions, IMeasurements, IProjectInfo } from '../utils/apitypes';
  import { makeApiCall } from '../utils/api';
  import { beginInProgress, endInProgress, notify } from '../utils/notify';
  import { indicators, type IDeriveOptions, type IIndicatorValue, type IIndicatorData } from '../utils/indicators';
  import InsightTable from './InsightTable.svelte';
  import InsightMap from './InsightMap.svelte';
  import { parseCsv, parseOldFormat, toNewFormat } from '../utils/routeparser';
  import MeasurementIntervalPopup from './MeasurementIntervalPopup.svelte';
  import { getLegendColorRGB } from '../utils/style';
  import InsightGraph from './InsightGraph.svelte';
  import type { IRouteInfo } from '../utils/types';
  import { PERMISSION_CONFIG_INSIGHT, PERMISSION_USE_INSIGHT, PERMISSION_VIEW_INSIGHT } from '../utils/permissions';
  import { parseMeasurementsCsv } from '../utils/routemeasurementsparser';
  import FloatingButtonContainer from './FloatingButtonContainer.svelte';
  import TimestampSelector from "./menu-buttons/TimestampSelector.svelte";
  import IndicatorSelector from "./menu-buttons/IndicatorSelector.svelte";
  import PointMeasurementSelector from "./menu-buttons/PointMeasurementSelector.svelte";
  import HereTrafficOptionSelector from "./menu-buttons/HereTrafficOptionSelector.svelte";
  import MapboxTrafficOptionSelector from "./menu-buttons/MapboxTrafficOptionSelector.svelte";
  import RouteSelector from "./menu-buttons/RouteSelector.svelte";
  import RouteManager from "./route-management/RouteManager.svelte";
      
  $: id = `project-${projectId}`;
  
  export let projectId: string;
  export let projectName: string;
  export let visible = false;

  let importPopupOpened = false;
  let importMeasurementsPopupOpened = false;
  let routeManagementSectionOpened = false;
  let tableSectionOpened = false;
  let tableRouteOpened = false;
  let graphRouteOpened = false;
  let importPopupClose: () => void | undefined;
  let importMeasurementsPopupClose: () => void | undefined;
  let importCsvContents = '';
  let importMeasurementsCsvContents = '';

  let measurement: IMeasurement | undefined;
  let insight: IInsight | undefined;
  let timestamps: string[] = [];
  let routeInfo: {[id: string]: IRouteInfo} = {};
  let selectedTimestamp;
  let followLiveData: boolean = true;
  let refreshRoutesFlag: boolean = false;

  let showSpeedValues: boolean = false;
  let showNdwSpeed: boolean = false;
  let showNdwFlow: boolean = false;
  let modeNdwActive: boolean = false;
  let modeNdwInactive: boolean = false;

  let exportFlowFlowGateIds = '';
  let exportFlowInProgress = false;

  let indicator = '';
  let trafficFlowMode: 'none' | 'coverage' | 'speedhq' | 'speedlq' | 'congestionhq' | 'congestionlq' = 'none';
  
  $: indicatorIdx = indicators.findIndex(x => x.id === indicator);

  let measurementSummaries: {[timestamp: string]: IMeasurement} = {};
  $: measurementSummaryList = Object.values(measurementSummaries).sort((a,b) => a.Timestamp.localeCompare(b.Timestamp));

  let congestionProfile: string[] = [];
  let refreshInterval;

  onMount(async () => {    
    refreshInterval = setInterval( async () => {
      if (visible && $PERMISSION_VIEW_INSIGHT) {
        await refreshTimestamps();
      }
    }, 20*1000 );
  });

  onDestroy( () => {
    clearInterval(refreshInterval);
  });

  $: if($PERMISSION_VIEW_INSIGHT) {
    setTimeout(async () => {
      beginInProgress();
      try {
        await refreshInsightStaticData();
        await refreshTimestamps('full');
      } finally {
        endInProgress();
      }
    }, 0);
  }

  function selectIndicator(event) {
    indicator = event.detail;
    repaint();
  }
  
  function setShowValues(event){
    showSpeedValues = event.detail;
    repaint();
  }

  function selectTrafficFlowMode(event) {
    trafficFlowMode = event.detail;
    console.log('traffic flow mode: ', trafficFlowMode);
    repaint();
  }

  function repaint() {
    console.log('InsightFrame repaint');
    tick();
  }

  function updateCsvContents(e: CustomEvent) {
      const value = (e.target as any).value;
      importCsvContents = value;
  }

  function updateMeasurementsCsvContents(e: CustomEvent) {
      const value = (e.target as any).value;
      importMeasurementsCsvContents = value;
  }

  function canImport(csv: string) {
    return csv.includes(':');
  }

  function reformatImport() {
    importCsvContents = toNewFormat(parseOldFormat(importCsvContents, projectId));
  }

  async function doImport() {
    if(!$PERMISSION_CONFIG_INSIGHT) {
      notify('U heeft geen rechten om de import te doen');
      return;
    }
    const data = parseCsv(importCsvContents, projectId);
  
    if (data.Gates.length === 0) {
      throw new Error('No data present. The import is aborted.');
    }

    await makeApiCall('project/insight/put', data);
    await refreshInsightStaticData();
    // Setting importPopupOpened to false does not work, so do it via the popup itself
    importPopupClose?.();
    notify(`Het importeren is gelukt. Er zijn ${data.Routes.length} routes met in totaal ${data.Gates.length} gate(s) geimporteerd`);
  }

  async function doImportMeasurements() {
    // This is hacky code to import missing measurements at a later moment.
    // The typical durations are derived by hard-codedly getting the measurement
    // for 7*24 hours in the future. This worked in our case. We have to improve this.
    // NOTE: We do NOT take SUMMER/WINTERTIME CHANGES during our measurement period into 
    // account here. When that occurs, the code must be adjusted.
    const REFERENCE_OFFSET_WEEKS = 1;
    const REFERENCE_OFFSET_MS = REFERENCE_OFFSET_WEEKS * 7 * 24 * 60 * 60 * 1000;

    if(!$PERMISSION_CONFIG_INSIGHT) {
      notify('U heeft geen rechten om de import te doen');
      return;
    }
    const data = parseMeasurementsCsv(importMeasurementsCsvContents, projectId);

    const measurementTimestamps = new Map<string, IImportedMeasurement[]>();
    for (const measurement of data.Measurements) {
      if (!measurementTimestamps.has(measurement.Timestamp)) {
        measurementTimestamps.set(measurement.Timestamp, []);
      }
      measurementTimestamps.get(measurement.Timestamp)?.push(measurement);
    }

    const allTimestampsSorted = timestamps.toSorted();

    // Server side must receive all info for a certain time stamp in one request
    // (otherwise newer requests overwrite data for older requests for the same
    // time stamp). So, determine distinct timestamps and do one query per timestamp.

    for (const [timestamp, measurements] of measurementTimestamps.entries()) {
      const dataForTimestamp: IImportedMeasurements = {
          ProjectId: data.ProjectId,
          Measurements: measurements
      };

      // Determine reference timestamp
      const referenceTimestamp = Date.parse(timestamp) + REFERENCE_OFFSET_MS;  // Ignores summer/winter time changes
      const referenceTimestampTxt = new Date(referenceTimestamp).toISOString();
      const ref = allTimestampsSorted.find((t) => t > referenceTimestampTxt);

      if (ref) {
        // Get reference data and enrich the dataForTimestamp with the typical duration
        const reference = await makeApiCall('project/insight/measurement/get', {
          ProjectId: projectId,
          Timestamp: ref,
          Congestions: []
        }) as IMeasurement;

        if (reference) {
          const sectionIds = reference.SectionMeasurements.SectionIds.split('\t');
          const typicalDurations = reference.SectionMeasurements.TypicalDurations.split('\t').map(x => parseFloat(x));
    
          for (const item of dataForTimestamp.Measurements) {
            const idx = sectionIds.indexOf(item.SegmentId);
            if (idx >= 0) {
              item.TypicalDuration = typicalDurations[idx];
            }
          }
        }
      }

      await makeApiCall('project/insight/measurements/import', dataForTimestamp);
    }

    await refreshInsightStaticData();
    // Setting importPopupOpened to false does not work, so do it via the popup itself
    importMeasurementsPopupClose?.();
    notify(`Het importeren is gelukt.`);
  }

  let refreshMeasurementTimer;
  function refreshMeasurement(preloader = true) {
    if (refreshMeasurementTimer) {
      clearTimeout(refreshMeasurementTimer);
    }
    refreshMeasurementTimer = setTimeout( async () => {
      refreshMeasurementTimer = undefined;
      if (preloader) {
        beginInProgress();
      }
        try {
          await refreshMeasurementImpl();
      } finally {
        if (preloader) {
          endInProgress();
        }
      }

    }, 300);
  }

  async function refreshInsightStaticData() {
    if(!$PERMISSION_VIEW_INSIGHT)
    {
      notify('U heeft geen rechten om de insight te bekijken');
      return;
    }

    beginInProgress();
    try {
      const data: IProjectInfo = {
        Id: projectId
      };
      const insightRaw = await makeApiCall('project/insight/get', data) as IInsight;
      insightRaw.Routes.sort((a,b) => a.Name.localeCompare(b.Name));
      insight = insightRaw;

      let i = 0;
      for (const route of insight.Routes){
        if (!routeInfo[route.Id]) {
            routeInfo[route.Id] = {
                hidden: false,
                color: getLegendColorRGB(i)
            }
        }
        i++;
      }
    } finally {
      endInProgress();
    }
  }

  async function refreshTimestamps(mode: 'full' | 'delta' = 'delta') {
    if(!$PERMISSION_VIEW_INSIGHT) {
      notify('U heeft geen rechten om de timestamps te bekijken');
      return;
    }

    const data: IMeasurementTimestampsOptions = {
          ProjectId: projectId,
          MinTimestamp: mode === 'full' ? undefined : timestamps?.[timestamps?.length - 1]
    };
    
    const newTimestamps = (await makeApiCall('project/insight/measurementTimestamps/get', data)).Timestamps;
    let changed = false;
    if (mode === 'full') {
      if (newTimestamps.length !== (timestamps ?? []).length) {
        timestamps = newTimestamps;
        timestamps.sort();
        changed = true;
      }
    } else {
      for (const timestamp of newTimestamps) {
        if (!timestamps.includes(timestamp)) {
          timestamps.push(timestamp);
          changed = true;
        }
      }
      timestamps.sort();
      if (changed) {
        timestamps = timestamps;
      }
    }

    if (changed) {
      if (followLiveData) {
        selectedTimestamp = timestamps.at(-1);
        refreshMeasurement();
      }
    }
  }

  async function refreshMeasurementImpl() {
    if(!$PERMISSION_VIEW_INSIGHT) {
      notify('U heeft geen rechten om de timestamps te bekijken');
      return;
    }

    if (!selectedTimestamp) {
      return;
    }

    measurement = await makeApiCall('project/insight/measurement/get', {
      ProjectId: projectId,
      Timestamp: selectedTimestamp,
      Congestions: congestionProfile
    });

    repaint();
  }

  async function refreshAll() {
    beginInProgress();
    try {
      await refreshInsightStaticData();
      await refreshTimestamps('full');
      await refreshMeasurement();
    } finally {
      endInProgress();
    }
  }

  function prepareSectionTableData(insight: IInsight, timestamp: string, measurement: IMeasurement, activeRoutesOnly: boolean = false) : IIndicatorData[] {
    const indicatorData: IIndicatorData[] = [];
    const sectionIds = measurement.SectionMeasurements.SectionIds.split('\t');
    const durations = measurement.SectionMeasurements.Durations.split('\t').map(x => parseFloat(x));
    const typicalDurations = measurement.SectionMeasurements.TypicalDurations.split('\t').map(x => parseFloat(x));
    
    const eligibleSectionIds = new Set(insight.Routes.filter(r => !activeRoutesOnly || !routeInfo[r.Id].hidden).flatMap(x => x.Sections));
    for (const section of [...eligibleSectionIds].map(x => insight.Sections.find(s => s.Id == x))) {
      const idx = sectionIds.indexOf(section.Id);
      if (idx < 0) {
        continue;
      }

      const values: IIndicatorValue[] = [];
      const options: IDeriveOptions = {
          distance: section.Distance,
          actualDuration: durations[idx] || 0,
          freeFlowDuration: section.FreeFlowDuration ?? 0,
          typicalDuration: typicalDurations[idx] || 0
      }
      for (const indicator of indicators) {
          values.push(indicator.derive(options));
      }
      indicatorData.push({
          Header: [section.GateFrom, section.GateTo],
          Timestamp: timestamp,
          Values: values
      });
    }
    return indicatorData;
  }

  function prepareRouteTableData(insight: IInsight, timestamp: string, measurement: IMeasurement, activeRoutesOnly: boolean = false) : IIndicatorData[] {
    const indicatorData: IIndicatorData[] = [];
    const sectionIds = measurement.SectionMeasurements.SectionIds.split('\t');
    const durations = measurement.SectionMeasurements.Durations.split('\t').map(x => parseFloat(x));
    const typicalDurations = measurement.SectionMeasurements.TypicalDurations.split('\t').map(x => parseFloat(x));
    for (const route of insight.Routes.filter(r => !activeRoutesOnly || !routeInfo[r.Id].hidden)) {
      const values: IIndicatorValue[] = [];
      const options: IDeriveOptions = {
        distance: route.Sections.reduce((acc, cur) => acc + insight.Sections.find((x) => x.Id === cur)?.Distance ?? 0, 0),
        actualDuration: route.Sections.reduce((acc, cur) => acc + durations[sectionIds.indexOf(cur)] ?? 0, 0),
        freeFlowDuration: route.Sections.reduce((acc, cur) => acc + insight.Sections.find((x) => x.Id === cur)?.FreeFlowDuration ?? 0, 0),
        typicalDuration: route.Sections.reduce((acc, cur) => acc + typicalDurations[sectionIds.indexOf(cur)] ?? 0, 0)
      }
      for (const indicator of indicators) {
          values.push(indicator.derive(options));
      }
      indicatorData.push({
          Header: [route.Name],
          Timestamp: timestamp,
          Values: values
      });
    }
    refreshRoutesFlag = !refreshRoutesFlag;
    return indicatorData;
  }

  function selectTimestamp(event) {
    followLiveData = false;
    selectedTimestamp = event.detail;
    refreshMeasurement();
  }

  async function doMeasurement() {
    if(!$PERMISSION_USE_INSIGHT) {
      notify('U heeft geen rechten om measurements aan te maken');
      return;
    }
    await makeApiCall('project/insight/measurement/perform', { Id: projectId } );
    refreshMeasurement();
  }

  async function changeMeasurementConfig(value: {intervals: IMeasurementIntervals, mapboxZoomLevel: number, hereFunctionalClass: number}) {
    if(!$PERMISSION_CONFIG_INSIGHT) {
      notify('U heeft geen rechten om de measurement config aan te passen');
      return;
    }
    const data: IMeasurementConfig = {
      ProjectId: projectId,
      Intervals: value.intervals,
      MapboxZoomLevel: value.mapboxZoomLevel
    }
    if (value.hereFunctionalClass) {
      const fc: number[] = [];
      for (let i=1; i<=value.hereFunctionalClass; i++) {
        fc.push(i);
      }
      data.HereFunctionalClasses = fc;
    }
    await makeApiCall('project/insight/measurement/config/set', data);
    
    await refreshInsightStaticData();

    notify('De meetinstellingen zijn opgeslagen.');
  }

  async function toggleFollowLiveData(newValue: boolean) {
    await tick();
    followLiveData = newValue;
    if (newValue) {
      selectedTimestamp = timestamps?.[timestamps?.length - 1];
      refreshMeasurement();
    }
  }

  async function updateMeasurementSummaries(timestamps: string[]) {
    if(!$PERMISSION_VIEW_INSIGHT) {
      notify('U heeft geen rechten om de timestamps te bekijken');
      return;
    }
    beginInProgress();
    try {
      const missing: string[] = [];
      const results: IMeasurement[] = [];
      for (const timestamp of timestamps ?? []) {
          const m = measurementSummaries[timestamp];
          if (m) {
              results.push(m);
          } else {
              missing.push(timestamp);
          }
      }
      
      const CHUNK_SIZE = 500;
      if (missing.length > 0) {
          missing.sort();
          let idx = 0;
          const collected: IMeasurement[] =[];
          while (idx < missing.length) {
            const low = missing[idx];
            const high = missing[idx + CHUNK_SIZE] ?? missing[missing.length - 1];
            const newMeasurements = await makeApiCall('project/insight/measurements/get', {
                ProjectId: projectId,
                TimestampFrom: low,
                TimestampTo: high
            }) as IMeasurements;
            for (const newMeasurement of newMeasurements.Measurements) {
                collected.push(newMeasurement);
            }
            idx += CHUNK_SIZE;
          }
          for (const item of collected) {
            measurementSummaries[item.Timestamp] = item;
          }
          measurementSummaries = measurementSummaries;
      }
    } finally {
      endInProgress();
    }
  }

  async function exportFlow() {
    function setProgress(n) {
      const CHUNK_DOWNLOAD_TIME = 10*1000;
      f7.progressbar.set('#exportFlowProgressBar', n, n > 0 ? CHUNK_DOWNLOAD_TIME : 0);
    }
    setProgress(0);
    try {
      exportFlowInProgress = true;
      const CHUNK_SIZE = 250;

      const filterIds = exportFlowFlowGateIds ? exportFlowFlowGateIds.split(',').map((x) => x.trim()) : undefined;

      let blobParts: Blob[] = [];
      let csv: string[] = [];
      csv.push(['Timestamp(UTC)', 'FlowGateId', 'Flow(veh/uur)', 'Speed(km/uur)'].join(',') + '\n');
        
      for (let start=0; start < timestamps.length; start += CHUNK_SIZE) {
        setProgress(100 * (start + CHUNK_SIZE) / (timestamps.length ?? 1));
        const measurements = await makeApiCall('project/insight/measurements/get', {
                ProjectId: projectId,
                TimestampFrom: timestamps[start],
                TimestampTo: timestamps[start + CHUNK_SIZE - 1] ?? timestamps[timestamps.length - 1],
                FlowGateIds: filterIds ?? []
            }) as IMeasurements;
        for (const measurement of measurements.Measurements) {
          const fm = measurement.FlowMeasurements;
          const ids = fm.FlowGateIds.split('\t');
          const flows = fm.Flows.split('\t');
          const speeds = fm.Speeds.split('\t');
          for (let idx=0; idx<ids.length; idx++) {
            const include = (filterIds === undefined) || (filterIds.includes(ids[idx]));
            if (!include) {
              continue;
            }
            csv.push([measurement.Timestamp, ids[idx], flows[idx], speeds[idx]].join(',') + '\n');
          }
          blobParts.push( new Blob(csv));
          csv = [];
        }
      }
      if (csv.length > 0) {
        blobParts.push( new Blob(csv));
      }
      setProgress(100);
      exportToFile(blobParts, 'text/csv', 'flowdata.csv');
    } finally {
      setProgress(0);
      exportFlowInProgress = false;
      f7.popup.close('#popup-export-flow');
    }
}

  function exportToFile(data: Blob[], contentType: string, fileName: string) {
      const timer = setTimeout(() => console.log('X'), 1000);
      const blob = new Blob(data, {type: contentType});
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
      clearTimeout(timer);
      URL.revokeObjectURL(url);
  }

</script>

<style lang="scss">
  @import '../assets/scss/colors';
  
  :global(.btn) {
    border-radius: 100px;
    padding: 8px;
    i {
      width: 24px;
      height: 24px;
    }
  }
  :global(.btn-graph) {
    border-radius: 100px;
    background: var(--Buko-sys-light-secondary-container, #FFDAD6);
    color: #{$secondary-fixed-on}
  }
  
  :global(.btn-menu){
    color: #{$surface-on-variant};
  }
</style>