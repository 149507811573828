﻿<FloatingButton 
        label="Mapbox traffic" 
        active={(congestionProfile??[]).length > 0}
        activeIcon="check"
        inactiveIcon=""
        popoverWidth="346px">
    
    <List ul={false}>
        <ListGroup>
            <ListItem title="Hinder" groupTitle />
            {#each MAPBOX_CONGESTION_OPTIONS as option}
                <ListItem 
                        checkbox 
                        checkboxIcon="end" 
                        value={option.value} 
                        title={option.label} 
                        checked={congestionProfile.includes(option.value)} 
                        onChange={onToggleCongestionMode}/>
            {/each}
        </ListGroup>
    </List>
</FloatingButton>

<script lang="ts">
    import {List, ListGroup, ListItem} from "framework7-svelte";
    import FloatingButton from "../FloatingButton.svelte";

    const MAPBOX_CONGESTION_OPTIONS: {label: string, value: string}[] = [
        {label: 'Ernstige hinder', value: 'Severe'},
        {label: 'Zware hinder', value: 'Heavy'},
        {label: 'Lichte hinder', value: 'Low'}
    ];

    export let congestionProfile: string[] = [];

    function onToggleCongestionMode(event) {
        console.log(event);
        const congestionMode: string = event.target.value;
        if(congestionProfile.includes(congestionMode)) {
            congestionProfile = congestionProfile.filter(x => x !== congestionMode);
        } else {
            congestionProfile = [...congestionProfile, congestionMode];
        }
    }
</script>