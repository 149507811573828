import type Framework7 from "framework7/types";

let toastf7: Framework7 | undefined = undefined;

export function setNotifyToastF7(f7: Framework7) {
    toastf7 = f7;
    if (inProgressLevel > 0) {
        toastf7?.progressbar.show();
    }
}

export function notify(msg: string) {
    const t = toastf7?.toast.create({
        closeButton: true,
        closeTimeout: 4000,
        horizontalPosition: 'center',
        position: 'top',
        text: msg
    });
    t.open();
}

// Sometimes, when quickly opening/closing after each other, the hide is not performed.
// We add a slight timeout before hiding (as a side effect, this also ensures that the
// progressbar remains a bit longer visible, which looks nice).
let hideTimeout;
let inProgressLevel = 0;

export function beginInProgress(mode: 'indeterminate' | 'determinate' = 'indeterminate') {
    if (inProgressLevel === 0) {
        if (mode === 'determinate') {
            toastf7?.progressbar.show(0);
        } else {
            toastf7?.progressbar.show();
        }
        if (hideTimeout) {
        clearTimeout(hideTimeout);
        }
    }
    inProgressLevel++;
}

export function setProgress(amount: number | undefined, delay: number) {
    if (inProgressLevel > 0) {
        toastf7?.progressbar.set(Math.round(amount), delay);
    }
}

export function endInProgress() {
    inProgressLevel--;
    if (inProgressLevel <= 0) {
        hideTimeout = setTimeout( () => {
        toastf7?.progressbar.hide();
        }, 50);
        inProgressLevel = 0;
    }
}

