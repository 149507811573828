<div on:mouseenter={openHoverControl} on:mouseleave={closeHoverControl}>
    <Button
            class="fb custom-hover {active ? 'fb--active' : ''}"
            on:click={openPopover}
            raised>
        {#if active && activeIcon}
            <Icon material="{activeIcon}" class="{activeIconClass}" size="18px"/>
        {/if}
        {#if !active && inactiveIcon}
            <Icon material="{inactiveIcon}" class="{inactiveIconClass}" size="18px"/>
        {/if}
        {label} <Icon material="arrow_drop_down" size="18px"/>
    </Button>
    <div id="fb-dummy-{popoverId}" class="fb-dummy"/>
</div>
<Popover 
        id={`popover-${popoverId}`}
        class="fb__popover" 
        style="{popoverHeight ? `height: ${popoverHeight};` : ''} {popoverWidth ? `width: ${popoverWidth};` : ''}" 
        arrow={false} 
        backdrop={false}
        containerEl="#fb-dummy-{popoverId}"
        bind:this={popoverComponent} 
        onPopoverClosed={() => isOpen = false}>
    <slot />
</Popover>
<Popover
        id={`hover-popover-${popoverId}`}
        class="fb__popover"
        style="{hoverControlHeight ? `height: ${hoverControlHeight};` : ''} {hoverControlWidth ? `width: ${hoverControlWidth};` : ''}"
        arrow={false}
        backdrop={false}
        animate={false}
        containerEl="#fb-dummy-{popoverId}"
        bind:this={hoverPopoverComponent}>
    <slot name="hover-control"/>
</Popover>

<script lang="ts">
    import { Button, Icon, Popover } from "framework7-svelte";
    export let label : string;

    let popoverId : string = Math.round(Math.random()*1_000_000).toString();
    let popoverComponent : Popover;
    let hoverPopoverComponent : Popover;
    let isOpen: boolean = false;

    export let activeIcon: string = "";
    export let activeIconClass: string = "";
    export let inactiveIcon: string = "";
    export let inactiveIconClass: string = "";
    export let active: boolean = false;
    export let popoverHeight: string = "";
    export let popoverWidth: string = "";
    export let hoverControlHeight: string = "";
    export let hoverControlWidth: string = "";

    /*
     * This is used because the popover doesn't close properly when another button is clicked while it is open.
     */
    function openPopover() {
        closeHoverControl();
        
        if(isOpen) {
            popoverComponent.instance().close();
            isOpen = false;
            return;
        }
        isOpen = true;
        popoverComponent.instance().open();
    }
    
    function openHoverControl() {
        if(isOpen){
            return;
        }
        hoverPopoverComponent.instance().open();
    }
    
    function closeHoverControl(){
        hoverPopoverComponent.instance().close();
    }
</script>

<style>
    :global(.fb.fb--active) {
        background-color: var(--fab-active-bg-color);
        color: var(--fab-active-color);
    }

    :global(.fb.fb--active:hover) {
        filter: brightness(90%);
        background-color: var(--fab-active-bg-color);
    }
    
    :global(.fb) {
        padding: 0px 16px;
        background-color: var(--fab-bg-color);
        color: var(--fab-color);
    }
    :global(.fb:hover) {
        filter: brightness(90%);
    }
    
    :global(.fb-dummy) {
        width: 0px;
    }
    :global(.fb__popover) {
        position: relative;
        top: 0 !important; /* This is used because F7 sets a wrong value when the popover is opened */
        left: 0 !important; /* This is used because F7 sets a wrong value when the popover is opened */
        margin-top: 8px;
        box-shadow: 1px 1px 3px #0000004D;
    }
    :global(.fb.button) {
        height: 32px;
    }

</style>